import Handlebars from "handlebars"; // used in superlatives.ts
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { ApiCache } from "./apiCache";

import cfg from "./buildConfig";
import { addEventContext, initFirebase, logEvent } from "./lib/analytics";
import { CacheItemType, DdcCtaPluginConfig, DealerSuperlativeConfig, ExtractedItem, LocationConfigs, SuperlativeConfig, VehicleCondition } from "./lib/models";
import { debugMessage, getCookie, isMobile } from "./lib/pluginUtil";
import { trackSuperlativeView } from "./superlatives/tracker";

(async function (window: Window, document, jQuery: any, tp, undefined) {
  if (tp === true) {
    console.log("TradePendingPlugin already exists so will not be loaded again.");
    return;
  }

  window.TradePendingPlugin = true;

  let DDC_API = null;
  let linkPromise: Promise<void>;
  let tp_linking_id: string;

  const TP_CTA_SELECTOR = "#tradepending-cta-container .tradepending-cta";
  const AUTONATION_DEALERSHIP_NAME = "AutoNation USA";
  const AA_BASE_URL = "https://app.autoapr.com";
  const DDC_LANDING_PAGE_LOCATION = "/tools/tradepending/snap-trade-in.htm";
  const DDC_LANDING_PAGE_LOCATIONS = {
    snap: { pageName: "TRADEPENDING_TRADE", location: "/tools/tradepending/value-your-trade.htm" },
    snapOffer: { pageName: "TRADEPENDING_OFFER", location: "/tools/tradepending/sell-my-car.htm" },
    reveal: { pageName: "TRADEPENDING_PAYMENTS", location: "/tools/tradepending/whats-my-buying-power.htm" },
    approve: { pageName: "TRADEPENDING_APPROVE", location: "/tools/tradepending/get-prequalified.htm" },
  };

  const AA_PRODUCT_MAP = {
    approve_banner: { product_name: "Approve", url: `${AA_BASE_URL}/approve/` },
    payment_explorer_banner: { product_name: "Payment Explorer", url: `${AA_BASE_URL}/explorer/` },
    payment_explorer_floating_button: { product_name: "Payment Explorer", url: `${AA_BASE_URL}/explorer/` },
    reveal_landing_page: { product_name: "Reveal", url: `${AA_BASE_URL}/reveal/`, use_condition_check: (vehicle) => true },
    approve_landing_page: { product_name: "Approve", url: `${AA_BASE_URL}/approve/` },
    payment_explorer_landing_page: { product_name: "Payment Explorer", url: `${AA_BASE_URL}/explorer/` },
    reveal_cta: { product_name: "Reveal", url: `${AA_BASE_URL}/reveal/`, use_condition_check: (vehicle) => true, cta_title: "Calculate Your Payment", cta_intent: "payment-calculator" },
    lease_cta: { product_name: "Lease", url: `${AA_BASE_URL}/reveal/lease/`, use_condition_check: (vehicle) => vehicle === VehicleCondition.NEW, cta_title: "Calculate Your Lease", cta_intent: "payment-calculator" },
    approve_cta: { product_name: "Approve", url: `${AA_BASE_URL}/approve/`, use_condition_check: (vehicle) => true, cta_title: "Get Prequalified Now", cta_intent: "pre-approval" },
    test_drive_cta: { product_name: "Test Drive Plus", url: `${AA_BASE_URL}/drive/`, use_condition_check: (vehicle) => true, cta_title: "Schedule a Test Drive", cta_intent: "test-drive" },
    combined_cta: { product_name: "Whatever", url: `${AA_BASE_URL}/explorer/`, use_condition_check: (vehicle) => true, ctas: ["reveal_cta", "lease_cta", "approve_cta", "test_drive_cta"] },
    combined_banner: { product_name: "Payment Explorer/Approve", url: (product: string) => `${AA_BASE_URL}/${product}/` },
  };

  const CTAS_MAP = {
    test_drive_v2_cta: { product_name: "Test Drive Plus", type: "test_drive_v2", use_condition_check: (vehicle) => true, cta_title: "Schedule a Test Drive", cta_intent: "test-drive" },
    availability_cta: { product_name: "Availability", type: "availability", use_condition_check: (vehicle) => true, cta_title: "Confirm Availability", cta_intent: "check-availability" },
    eprice_cta: { product_name: "ePrice", type: "eprice", use_condition_check: (vehicle) => true, cta_title: "Get ePrice", cta_intent: "eprice" },
  };

  if (jQuery === undefined || typeof jQuery !== "function") {
    if (typeof window.addEventListener !== undefined) {
      // Everything but IE
      window.addEventListener(
        "load",
        () => {
          jQuery = window.jQuery;
          setup_tradepending();
        },
        false
      );
    } else if (typeof window.attachEvent !== undefined) {
      window.attachEvent("onload", () => {
        jQuery = window.jQuery;
        setup_tradepending();
      });
    }
  } else {
    setup_tradepending();
  }

  function setup_tradepending() {
    if (jQuery === undefined || typeof jQuery !== "function") {
      console.error("TradePending: jQuery does not exist, can't setup TradePending SNAP plugin");
      return;
    }

    require("./lib/typeahead");
    require("./lib/colorbox");
    require("./lib/ajax");

    class TPUtil {
      originalOpen: () => void;
      doThrottleMutationObservers = throttle(this.doSetupMutationObservers, 200, { leading: false });
      setupMutationObservers = debounce(this.doThrottleMutationObservers, 100);

      async sleep(time: number) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }

      simpleDebounce(fn, delay) {
        let timeOutId;
        return (...args) => {
          if (timeOutId) {
            clearTimeout(timeOutId);
          }
          timeOutId = setTimeout(() => {
            fn(...args);
          }, delay);
        };
      }

      tp_decorate(url) {
        url += "&session_aid=" + tp_analytics_session_id;
        let r = document.cookie.match(/tpa_user=(.*?)(?:;|$)/);
        if (r) {
          url += "&user_aid=" + r[1];
        }
        r = document.cookie.match(/tpa_actions=(.*?)(?:;|$)/);
        if (r) {
          url += "&actions=" + r[1];
        }
        r = document.cookie.match(/tpa_vdp=(.*?)(?:;|$)/);
        if (r) {
          url += "&survey_id=" + r[1];
        }
        if (tp_linking_id) {
          url += `&linking_id=${tp_linking_id}`;
        }
        if (tp_analytics_session_id_is_new) {
          url += "&newsession=true";
          tp_analytics_session_id_is_new = false;
        }
        return url;
      }

      referer_decorate(url) {
        let r = document.cookie.match(/tp_initial_url=(.*?)(?:;|$)/);
        if (r) {
          url += "&initial_url=" + encodeURIComponent(r[1]);
        }
        r = document.cookie.match(/tp_referrer_url=(.*?)(?:;|$)/);
        if (r) {
          url += "&referrer_url=" + encodeURIComponent(r[1]);
        }
        r = document.cookie.match(/tp_snap_lead_id=(.*?)(?:;|$)/);
        if (r) {
          url += "&snap_lead_id=" + encodeURIComponent(r[1]);
        }
        return url;
      }

      vehicle_decorate(url, plugin_cfg) {
        if (plugin_cfg.vehicle_condition && plugin_cfg.vehicle_condition.condition) {
          url += "&cd=" + (plugin_cfg.vehicle_condition.condition === VehicleCondition.NEW ? "n" : "u");
        }
        if (plugin_cfg.price) {
          url += "&price=" + plugin_cfg.price;
        }
        return url;
      }

      get_primary_ga_id() {
        if (typeof ga_id !== "undefined") {
          const split = ga_id.split(",");
          return split[0].trim();
        } else {
          return null;
        }
      }

      ga_decorate(url) {
        try {
          const gao = get_ga_object();
          if (gao) {
            const primary_ga_id = this.get_primary_ga_id();
            if (primary_ga_id != null) {
              let tracker = getGaTracker(primary_ga_id);
              if (tracker == null && gao && gao.getAll && typeof gao.getAll === "function") {
                tracker = gao.getAll()[0];
              }
              gao("require", "linker");
              if (window.gaplugins && window.gaplugins.Linker && tracker) {
                const linker = new window.gaplugins.Linker(tracker);
                url = linker.decorate(url, true);
                debugMessage("GA decorated URL: " + url);
              }
            }
          }
        } catch (ex) {
          console.log("Error in TradePending.ga_decorate", ex);
        }
        try {
          const r = document.cookie.match(/_gcl_aw=(.*?)(?:;|$)/);
          if (r) {
            url += "&gcl_aw_id=" + r[1];
          }
        } catch (ex) {
          console.log("Error in TradePending.ga_decorate extracting gcl_aw_id", ex);
        }
        return url;
      }
      shift_decorate(url) {
        let r = document.cookie.match(/tp_shift_session_id=(.*?)(?:;|$)/);
        if (r) {
          url += "&shift_session_id=" + r[1];
        }
        return url;
      }
      getPath() {
        const parser = document.createElement("a");
        parser.href = window.location.href;
        return parser.pathname.replace(/^\/*/, "");
      }

      getPluginLocation() {
        return findPluginLocation(this.getPath())?.location;
      }

      isLandingOrInline() {
        const plugin_location = tpUtil.getPluginLocation();
        const plugin = plugin_data[plugin_location];

        return plugin?.inline_mobile || plugin_location == "landing";
      }
      isDdcLandingPage() {
        for (const locationConfig of Object.values(DDC_LANDING_PAGE_LOCATIONS)) {
          if (window.location.pathname.includes(locationConfig.location)) {
            return true;
          }
        }
        return this.isOldDdcLandingPage();
      }

      isOldDdcLandingPage() {
        return window.location.pathname.includes(DDC_LANDING_PAGE_LOCATION);
      }

      isDdcPageNameMatch(pageName: string, matchedNames: string[]) {
        return matchedNames.some((matchedName) => pageName.includes(matchedName));
      }

      isIE() {
        const ua = window.navigator.userAgent;
        return ua.indexOf("MSIE ") > 0 || ua.indexOf("Trident/") > 0 || ua.indexOf("Edge/") > 0;
      }

      exists(str) {
        return typeof str !== "undefined" && str.length > 0;
      }

      getCssSelector(plugin) {
        return plugin.css_selector;
      }

      do_refresh(pluginLocationConfigs: LocationConfigs, triggerFunction: () => void) {
        if (process.env.SUPERLATIVES_ENABLED) {
          setup_superlatives();
        }
        if (triggerFunction) {
          triggerFunction();
          this.setup_refresh_events(pluginLocationConfigs, triggerFunction);
        }
      }

      setup_refresh_events(pluginLocationConfigs: LocationConfigs, triggerFunction: () => void) {
        let location_config = { ...(plugin_location_configs[tpUtil.getPluginLocation()] || {}) };
        let observe_id: any = null;
        const self = this;
        const refreshFunction = debounce(() => this.do_refresh(pluginLocationConfigs, triggerFunction), 1000);
        if (location_config?.has_been_setup) {
          return;
        } else {
          location_config.has_been_setup = true;
        }
        if (location_config.ajax_url) {
          if (!this.originalOpen) {
            // @ts-ignore
            this.originalOpen = XMLHttpRequest.prototype.open;
          }
          // @ts-ignore
          XMLHttpRequest.prototype.open = function (method: string, url: string | URL, async: boolean, username?: string | null, password?: string | null) {
            let shouldRefresh = false;
            location_config = pluginLocationConfigs[tpUtil.getPluginLocation()];
            if (url.toString().match(new RegExp(location_config.ajax_url)) && !url.toString().includes(plugin_ajax_url)) {
              shouldRefresh = true;
            }
            this.addEventListener(
              "readystatechange",
              function () {
                if (this.readyState === 4 && shouldRefresh) {
                  debugMessage("Ajax URL refresh triggered by URL: " + url.toString());
                  if (location_config.force_reload_on_ajax) {
                    window.TradePendingReloadSuperlatives = true;
                  }
                  self.do_refresh(pluginLocationConfigs, triggerFunction);
                }
              },
              false
            );
            self.originalOpen.apply(this, arguments);
          };
          const originalFetch = window.fetch;
          window.fetch = function (input, init) {
            if (input?.toString().match(new RegExp(location_config.ajax_url)) && !input?.toString().includes(plugin_ajax_url)) {
              if (location_config.force_reload_on_ajax) {
                window.TradePendingReloadSuperlatives = true;
              }

              if (location_config.observer_selector) {
                self.setupMutationObservers(location_config.observer_selector, 0, refreshFunction, location_config.observer_selector_reinit);
              }
              self.do_refresh(pluginLocationConfigs, triggerFunction);
            }
            return originalFetch.apply(this, arguments);
          };
        }
        if (location_config.watch_hash_change) {
          window.addEventListener(
            "hashchange",
            () => {
              self.do_refresh(pluginLocationConfigs, triggerFunction);
            },
            false
          );
        }
        if (location_config.observer_selector != null && location_config.observer_selector.length > 0) {
          observe_id = location_config.observer_selector;
        } else if (plugin_config.website_vendor_name === "CDK-NextGen" || plugin_config.website_vendor_name === "Sincro") {
          observe_id = "#main, .deck";
        }

        if (observe_id != null) {
          this.setupMutationObservers(observe_id, 0, refreshFunction, location_config.observer_selector_reinit);
        } else if (plugin_config.website_vendor_name === "DealerInspire") {
          debugMessage("Setup Superlatives listener for vrp-ready");
          jQuery(document).on("vrp-ready vrp-ajax-complete", () => {
            debugMessage("Superlatives got vrp-ready event");
            self.do_refresh(pluginLocationConfigs, triggerFunction);
          });
        }
        if ("onorientationchange" in window) {
          window.addEventListener("orientationchange", () => {
            window.TradePendingReloadSuperlatives = true;
            self.do_refresh(pluginLocationConfigs, triggerFunction);
          });
        }
      }

      doSetupMutationObservers(observe_id: string, retryCount: number, refreshFunction: () => void, observer_selector_reinit?: string) {
        const self = this;

        debugMessage("Setup Superlatives MutationObserver on: " + observe_id);

        const observedElement = document.getElementById(observe_id);
        let observedElements;
        if (!observedElement) {
          observedElements = document.querySelectorAll(observe_id);
        }
        const reinitializeElements = (observer_selector_reinit && document.querySelectorAll(observer_selector_reinit)) || [];
        debugMessage("Setup Superlatives MutationObserver reinitializeElements" + reinitializeElements);

        if (observedElement || observedElements) {
          const mutationObserver = new MutationObserver((mutations) => {
            mutations.some((mutation) => {
              if (observedElement && mutation.target === observedElement) {
                debugMessage("Element hit in MutationObserver:" + observedElement);
                return refreshFunction();
              } else if (observedElements) {
                for (const el of observedElements) {
                  if (el === mutation.target) {
                    debugMessage("Element hit in MutationObserver:" + el);
                    let setupAgain = false;
                    for (let j = 0; j < reinitializeElements.length && !setupAgain; j++) {
                      const reinitElement = reinitializeElements[j];
                      if (mutation.target === reinitElement) {
                        setupAgain = true;
                      }
                    }
                    if (setupAgain) {
                      debugMessage("Superlatives MutationObserver: setting up again mutation found" + observer_selector_reinit);

                      setTimeout(() => self.setupMutationObservers(observe_id, retryCount, refreshFunction, observer_selector_reinit), 100);
                    }
                    return refreshFunction();
                  }
                }
              }
            });
          });

          if (observedElement) {
            mutationObserver.observe(observedElement, {
              attributes: false,
              characterData: false,
              childList: true,
              subtree: true,
              attributeOldValue: false,
              characterDataOldValue: false,
            });
          } else if (observedElements) {
            observedElements.forEach((el) => {
              mutationObserver.observe(el, {
                attributes: false,
                characterData: false,
                childList: true,
                subtree: true,
                attributeOldValue: false,
                characterDataOldValue: false,
              });
            });
          }
        } else {
          if (retryCount <= 3) {
            setTimeout(() => {
              self.doSetupMutationObservers(observe_id, retryCount + 1, refreshFunction, observer_selector_reinit);
            }, 1000 * retryCount);
          }
        }
      }

      parse_query_params(url: string) {
        try {
          const result = {};
          const params = url.substring(1).split("&");
          for (const param of params) {
            const key_value = param.split("=");
            if (key_value.length === 2) {
              result[key_value[0]] = decodeURIComponent(key_value[1]);
            } else {
              result[key_value[0]] = "";
            }
          }
          return result;
        } catch (e) {
          console.log("TP exception parsing query params: " + e, e.message);
          return {};
        }
      }

      add_utm_params_to_iframe(widget_html: string, utm_only: boolean) {
        const iframe_regex = /<iframe.*\/>|<iframe.*><\/iframe>/;
        const iframe = widget_html.match(iframe_regex);
        const src = iframe[0].match(/src=".*"/)[0];
        const split_url = src.split("src=")[1];
        let url = split_url.slice(1, split_url.length - 1);
        if (url) {
          if (utm_only) {
            const params = tpUtil.parse_query_params(window.location.search);
            const new_utm_search = Object.keys(params).reduce((memo, paramKey) => {
              if (paramKey.startsWith("utm")) {
                memo = memo + `${paramKey}=${params[paramKey]}`;
              }
              return memo;
            }, "&");
            if (url.includes("?")) {
              url = `${url}${new_utm_search}`;
            } else {
              url = url + "?" + new_utm_search.slice(1);
            }
          } else {
            url = this.tp_decorate(url);
            url = this.referer_decorate(url);
            url = this.ga_decorate(url);
          }
        }
        if (url?.endsWith("?")) {
          url = url.slice(0, src.length - 1);
        }
        const new_src = `src='${url}'`;

        const new_iframe = iframe[0].replace(/src=".*"/, new_src);
        return widget_html.replace(iframe_regex, new_iframe);
      }
    }

    const plugin_config = cfg.plugin_config;
    const plugin_id = cfg.plugin_id;
    const dealer_id = cfg.dealer_id;
    const autoapr_dealer_id = cfg.autoapr_dealer_id;
    const plugin_ajax_url = cfg.plugin_ajax_url;
    const plugin_server = cfg.plugin_server;
    const es_url = cfg.es_url;
    const country = cfg.country;
    const plugin_data = cfg.plugin_data;
    const sellnow_config = cfg.sellnow_config as { instant_locations?: string[] };
    const dealer_superlative_config = cfg.dealer_superlative_config as DealerSuperlativeConfig;
    const plugin_location_configs = cfg.plugin_location_configs as LocationConfigs;
    const global_styles = cfg.global_styles;
    const ga_id = cfg.ga_id;
    const collect_survey = cfg.collect_survey;
    const superlative_global_css = cfg.superlative_global_css;
    const superlative_config = cfg.superlative_config as SuperlativeConfig;
    const autoapr_config = cfg.autoapr_config;
    const ctas_config = cfg.ctas_config;
    const vdp_tracking_config = cfg.vdp_tracking_config;

    const tpUtil = new TPUtil();
    const superlativesApiCache = new ApiCache(CacheItemType.SUPERLATIVE);
    const vehiclesConditionApiCache = new ApiCache(CacheItemType.VEHICLE_CONDITION);
    const vinsFromStockNumbersApiCache = new ApiCache(CacheItemType.STOCK_NUMBER_VIN);

    // Start of SNAP Superlatives
    let superlatives_setup_called = false;
    let currentPluginLocation;
    let currentPluginIsMobile;

    function add_superlative_config_iframe() {
      const url = "//" + plugin_server + "/superlatives/config_iframe";
      const html = "<iframe id='tradepending-superlatives-iframe' src='" + url + "' width='0px' height='0px' frameborder='0' scrolling='no' style='display: none;'></iframe>";

      jQuery("body").append(html);
    }

    function get_superlative_defaults() {
      return {
        poweredBy: undefined,
        icon: undefined,
        fonts: {
          rootSize: "10px",
        },
        border: {
          color: "#b5b4b6",
        },
        borderRadius: "8px",
        button: {
          borderRadius: "0px",
        },
        container: {
          maxWidth: "400px",
        },
        colors: {
          container: {
            background: "white",
            font: "black",
          },
          primary: {
            background: "#f74d03",
            font: "white",
          },
          secondary: {
            background: "#f76841",
            font: "white",
          },
          gradient: {
            start: undefined,
            end: "#EFEDED",
          },
        },
      };
    }

    function handle_superlative_templates(location_config) {
      var variables = get_superlative_defaults();
      var roundedButton = true;
      if (location_config.variables) {
        roundedButton = location_config.rounded_buttons;
        Object.keys(location_config.variables).forEach(function (key) {
          var value = location_config.variables[key];
          var path = key.split("-");
          var variable = variables;
          path.forEach(function (pathVal) {
            var temp = variable[pathVal];
            if (typeof temp == "object") {
              variable = temp;
            } else if (temp) {
              variable[pathVal] = value;
            }
          });
        });
      }
      if (roundedButton) {
        variables.button = {
          borderRadius: roundedButton ? "10px" : "0px",
        };
      }

      variables.icon = {
        size: "40px",
      };
      variables.poweredBy = {
        height: "17px",
        width: "85px",
      };
      variables.colors.gradient.start = variables.colors.container.background;
      return variables;
    }

    function shouldShowSuperlatives(loc) {
      const cleanLoc = loc?.replace("_mobile", "") || "";
      const isVdpOrSRP = cleanLoc.startsWith("vdp") || cleanLoc.startsWith("srp") || cleanLoc.endsWith("srp") || cleanLoc.endsWith("vdp");
      return isVdpOrSRP && showSuperlativeBasedOnStatus() && shouldProcessSuperlatives();
    }

    var INACTIVE_STATUSES = ["canceled_disabled", "hold"];

    function isActiveStatus() {
      return INACTIVE_STATUSES.indexOf(superlative_config.status) == -1;
    }

    function showSuperlativeBasedOnStatus() {
      if (isActiveStatus()) {
        if (superlative_config.status == "testing") {
          return document.cookie.match(/tp_test_product=true/) != null || window.location.hostname.match(/\.dev\.dealerinspire\.com/);
        } else if (superlative_config.status == "pending") {
          postAnalytics("notify");
          return false;
        } else if (isBlockedStagingSite(superlative_config.status)) {
          return false;
        } else {
          return true;
        }
      }
    }

    function shouldProcessSuperlatives() {
      var path = tpUtil.getPath();
      var regex = dealer_superlative_config && dealer_superlative_config.ignore_regex;
      if (!regex) {
        return true;
      }
      var match = !path.match(new RegExp(regex, "i"));
      if (!match) {
        if (process.env.DEBUG_ENABLED) console.log("Skipping superlatives because the regex is ignoring this path:", path, regex);
      }
      return match;
    }

    function insert_ddc_superlatives(location_config, vin_list) {
      let location_config_html = location_config.html_compiled || location_config.html;
      if (location_config.enable_build_data) {
        location_config_html = jQuery(`<div>${location_config_html}</div>`);
        const buildDataHtml = jQuery(location_config_html).find(".tp-build-data-wrapper");
        buildDataHtml.removeAttr("hidden");
        location_config_html = location_config_html.html();
      }
      const superlatives_html = Handlebars.compile(location_config_html);
      const selector = location_config.selector || "vehicle-media";
      if (process.env.DEBUG_ENABLED) console.log(`Insert superlative widgets at ${selector} for vins:`, vin_list);
      fetch_superlatives(vin_list, 0).then((result) => {
        const datamap = {};
        result.data.forEach((record) => {
          datamap[record.vin] = record;
        });
        DDC_API.insertOnce(selector, (elem, meta) => {
          const superlative_info = datamap[meta.vin];
          if (!superlative_info) {
            return;
          }
          const missing = jQuery(elem).parent().find(`.${getRootClass()} .tp-superlative-data[data-vin=${superlative_info}]`).length === 0;
          if (!missing) {
            return;
          }
          if (process.env.DEBUG_ENABLED) {
            console.log(`Insert superlative widget for vin: ${meta.vin} with superlative: ${superlative_info.superlative}`);
          }
          const outputElem = build_superlatives_html(superlative_info, result.template_html, superlatives_html, location_config);
          let widget_elem = outputElem.get(0);
          DDC_API.append(elem, widget_elem);
          finalize_inserted_element(location_config, superlative_info, widget_elem, outputElem);
        });
        if (result.data?.length === 1) {
          postAnalytics("superlative_" + get_superlative_name_key(result.data[0].superlative));
        } else {
          postAnalytics("superlative_multiple");
        }
      });
    }

    function setup_superlatives_ddc() {
      if (superlatives_installed) {
        console.log("TradePending skipping setting up Superlatives for DDC since its already been done");
        return;
      }
      superlatives_installed = true;
      if (process.env.DEBUG_ENABLED) {
        console.log("Setting up superlatives DDC with config data:", superlative_config);
      }
      const location_config = setup_superlatives_location_config(superlative_config);
      if (location_config.disabled) {
        superlatives_setup_called = true;
        return;
      }
      if (!superlatives_setup_called) {
        add_superlative_config_iframe();
        superlatives_setup_called = true;
      }
      handleLocationOrMobileChange(location_config);

      DDC_API.subscribe("vehicle-data-updated-v1", (data) => {
        if (process.env.DEBUG_ENABLED) {
          console.log("DDC vehicle-data-updated-v1", data);
        }
        if (!data.payload?.vehicleData) {
          if (process.env.DEBUG_ENABLED) {
            console.log("DDC vehicle-data-updated-v1 returned no vehicle data");
          }
        } else {
          const vin_list = data.payload.vehicleData.map((data) => data.vin);
          insert_ddc_superlatives(location_config, vin_list);
        }
      });
    }

    const do_throttled_setup_superlatives = throttle(do_setup_superlatives, 200, { leading: false });
    const setup_superlatives = debounce(do_throttled_setup_superlatives, 100);

    function setup_superlatives_location_config(superlative_config: SuperlativeConfig) {
      const pluginLocation = tpUtil.getPluginLocation();
      const pluginLocationConfig = plugin_location_configs[pluginLocation];

      let superlativeLocation = pluginLocation;
      if (pluginLocationConfig?.location_type && pluginLocationConfig?.location_type != "auto") {
        superlativeLocation = pluginLocationConfig?.location_type;
      }

      const desktopConfig = superlative_config.locations[superlativeLocation];
      const mobileConfig = superlative_config.locations[superlativeLocation + "_mobile"];

      let locationConfig;
      // @ts-ignore
      if (isMobile() && mobileConfig !== null) {
        locationConfig = { ...desktopConfig, ...mobileConfig };
      } else {
        locationConfig = { ...desktopConfig };
      }
      if (locationConfig.css == null) {
        locationConfig.css = "";
      }
      if (locationConfig.template_css == null) {
        locationConfig.template_css = "";
      }
      const srp_config = superlative_config.locations["srp"];
      if (!locationConfig?.html_compiled) {
        locationConfig.html_compiled = srp_config?.html_compiled;
      }
      if (!locationConfig?.css_compiled) {
        locationConfig.css_compiled = srp_config?.css_compiled;
      }
      if (!locationConfig?.variables) {
        locationConfig.variables = srp_config?.variables;
      }
      return locationConfig;
    }

    function addOrReplaceSuperlativesCssTag(locationConfig) {
      const superlativeCssElement = jQuery("#tradepending-superlatives-css");
      const variables = handle_superlative_templates(locationConfig);
      const styles = "<style id='tradepending-superlatives-css'>" + superlative_global_css + locationConfig.template_css + locationConfig.css + locationConfig.css_compiled + "</style>";
      const superlativeCssTemplate = Handlebars.compile(styles);
      const superlativeCss = superlativeCssTemplate(variables);
      if (superlativeCssElement.length === 1) {
        superlativeCssElement.replaceWith(superlativeCss);
      } else {
        jQuery("head").append(superlativeCss);
      }
    }

    function handleLocationOrMobileChange(locationConfig) {
      const pluginLocation = tpUtil.getPluginLocation();
      // @ts-ignore
      const mobile = isMobile();
      if (pluginLocation === currentPluginLocation && mobile === currentPluginIsMobile) {
        return;
      }
      currentPluginLocation = pluginLocation;
      currentPluginIsMobile = mobile;
      addOrReplaceSuperlativesCssTag(locationConfig);
    }

    function do_setup_superlatives() {
      if (process.env.DEBUG_ENABLED) console.log("Setting up superlatives with config data:", superlative_config);
      var location_config = setup_superlatives_location_config(superlative_config);
      if (location_config.disabled) {
        superlatives_setup_called = true;
        return;
      }
      if (!superlatives_setup_called) {
        const pluginLocation = tpUtil.getPluginLocation();
        add_superlative_config_iframe();
        if (process.env.DEBUG_ENABLED) console.log("superlatives location_config: ", pluginLocation, location_config);
        superlatives_setup_called = true;
        plugin_location_configs[tpUtil.getPluginLocation()].has_been_setup = false;
      }
      handleLocationOrMobileChange(location_config);

      if (process.env.DEBUG_ENABLED) console.log("Inserting TP Superlatives using insert_selector " + location_config.selector + " and insert method: " + location_config.insert_method);
      let location_config_html = location_config.html_compiled || location_config.html;
      if (location_config.enable_build_data) {
        location_config_html = jQuery(`<div>${location_config_html}</div>`);
        const buildDataHtml = jQuery(location_config_html).find(".tp-build-data-wrapper");
        buildDataHtml.removeAttr("hidden");
        location_config_html = location_config_html.html();
      }

      const superlatives_html = Handlebars.compile(location_config_html);
      var vin_selector_raw = location_config.vin_selector;
      const vin_list: string[] = [];
      var outputContainer = {};
      var container_node = jQuery(location_config.node_selector);
      var loaded;
      if (process.env.DEBUG_ENABLED) console.log("TP Superlatives: Looking for vins:", location_config.node_selector, vin_selector_raw);
      if (container_node.length > 0) {
        container_node.each(function (index, element) {
          reportHiddenNodeSelector(jQuery(element), location_config.node_selector);
          loaded = jQuery(element).data("tp-superlative-loaded");
          if (!loaded || window.TradePendingReloadSuperlatives) {
            const vin = getDataFromElement(element, vin_selector_raw, ExtractedItem.VIN);
            if (vin) {
              vin_list.push(vin);
            }
            outputContainer[vin] = element;
            jQuery(element).data("tp-superlative-loaded", true);
          }
        });
        window.TradePendingReloadSuperlatives = false;
      } else {
        console.error("TradePending: Superlatives didn't find any nodes, check the node_selector:", location_config.node_selector);
        return;
      }
      if (process.env.DEBUG_ENABLED) console.log("Superlatives found vins:", vin_list);

      if (vin_list.length == 0) {
        if (loaded) {
          if (process.env.DEBUG_ENABLED) console.log("TP Superlatives already loaded, we're not going to refresh.");
          return;
        }
        console.error("TradePending: Superlatives found no vins on this page.  Check the vin_selector:", vin_selector_raw);
      } else {
        fetch_superlatives(vin_list, 0).then((result) => {
          var insert_failed = false;
          jQuery.each(result.data, function (index, superlative_info) {
            if (draw_superlative_box(index, superlative_info, outputContainer, superlatives_html, location_config, result.template_html)) {
              insert_failed = true;
            }
          });
          if (insert_failed) {
            console.error("TradePending: Superlatives Error: check the insert selector", location_config.selector);
          } else if (result.data.length == 1) {
            postAnalytics("superlative_" + get_superlative_name_key(result.data[0].superlative));
          } else {
            postAnalytics("superlative_multiple");
          }
        });
      }
    }

    async function fetch_superlatives(vin_list, retries) {
      if (!vin_list || vin_list.length === 0) {
        return;
      }
      if (typeof superlative_config.allow_config === "undefined" && retries < 5) {
        if (process.env.DEBUG_ENABLED) {
          console.log("no config found, settimeout", superlative_config);
        }
        setTimeout(() => {
          fetch_superlatives(vin_list, retries + 1);
        }, 200);
        return await do_fetch_superlatives(vin_list);
      } else {
        return await do_fetch_superlatives(vin_list);
      }
    }

    async function do_fetch_superlatives(vin_list) {
      if (!vin_list || vin_list.length === 0) {
        return;
      }
      const superlativePluginLocation = tpUtil.getPluginLocation();
      var url = "//" + plugin_server + "/superlatives/lookup?did=" + dealer_id + "&plugin_id=" + plugin_id + "&location=" + superlativePluginLocation;
      if (superlative_config.user_id) {
        url = url + "&user_id=" + superlative_config.user_id;
      } else if (superlative_config.demo) {
        url = url + "&demo=" + superlative_config.demo;
      }
      var current_url = new URL(window.location.href);
      var icon_set = current_url.searchParams.get("tp_icon");
      if (icon_set) {
        url += "&icon_set=" + icon_set;
      }
      await superlativesApiCache.initCacheForService(vin_list, url);
      return buildSuperlativeResult(vin_list, superlativePluginLocation);
    }

    function buildSuperlativeResult(items: string[], location: string) {
      const vinData = items.reduce((acc: any[], item: string) => {
        if (superlativesApiCache.cache[item]?.result) {
          acc.push(superlativesApiCache.cache[item]?.result);
        }
        return acc;
      }, []);
      return { data: vinData, template_html: superlativesApiCache.templateHtml[location] };
    }

    function get_superlative_name_key(superlative_name) {
      return superlative_name.replace(/\s/g, "_").toLowerCase();
    }

    function is_custom_with_sidecar(superlative_info) {
      return superlative_info.details_superlative.find(function (detailSuperlative) {
        return detailSuperlative.show_as_sidecar;
      });
    }

    function get_custom_superlative_title(superlative_info) {
      var title = superlative_info.details_superlative.find(function (detailSuperlative) {
        if (detailSuperlative.show_as_sidecar) {
          return detailSuperlative.title;
        }
      });
      return title;
    }

    function add_superlative_sidecar(superlative_info, el) {
      var htmlString;
      if (dealer_superlative_config && superlative_info.details) {
        if (is_custom_with_sidecar(superlative_info)) {
          var title = get_custom_superlative_title(superlative_info);
          if (title && title.title && superlative_info.details.superlative !== title.name) {
            htmlString = '<div class="tp-cpo-wrapper"><div class="tp-cpo-sidecar">' + title.title + "</div></div>";
          }
        }
      }

      if (htmlString) {
        var html = jQuery(htmlString);
        var container = el.filter(".tp-superlative-container").first();
        if (container.length == 0) {
          container = el.find(".tp-superlative-container").first();
        }
        container.prepend(html);
      }
    }

    function setup_superlative_click(el, location_config, superlative_info) {
      const fbPayload = {
        vin: superlative_info.vin,
        superlative: superlative_info.superlative,
        superlative_id: superlative_info.superlative_id,
      };
      jQuery(el)
        .find(".tp-superlative")
        .hover(function () {
          jQuery(this).css("cursor", "pointer");
        });
      let buildDataWidgetClicked = false;

      const onClickHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        var target = jQuery(e.currentTarget);

        var more_info = target.find(".tp-superlative-data");
        var badge_type_el = target.find("[data-badge-type]");
        var superlative = more_info.data("superlative");
        var superlative_id = more_info.data("superlative-id");
        var vin = more_info.data("vin");
        var placeholder = more_info.data("placeholder");
        var moreInfoDealer = more_info.data("dealer-id") || dealer_id;
        let autobioWidgetClicked = false;
        var url;

        if (placeholder) {
          url =
            "//" +
            plugin_server +
            "/superlatives/config?newtab=true&" +
            jQuery.param({
              vin,
              dealer_id: moreInfoDealer,
              placeholder,
            });
        } else {
          if (!superlative || !vin) {
            console.error("TradePending: Missing superlative/vin that is needed to show more info.");
            return;
          }
          const pluginLocation = tpUtil.getPluginLocation();

          let badgeType = "superlatives";
          let snapAfterType: SnapAfterOptions["type"] = "srp-superlative";
          if (badge_type_el) {
            badgeType = badge_type_el.data("badgeType");
          }
          if (badgeType === "autobio") {
            url = `//${plugin_server}/apps/autobio/preview/${moreInfoDealer}/${vin}`;
            snapAfterType = "srp-autobio";
            autobioWidgetClicked = true;
          } else {
            url =
              "//" +
              plugin_server +
              "/superlatives/superlativesreport?" +
              jQuery.param({
                vin,
                did: moreInfoDealer,
                location: pluginLocation,
                selectedSuperlative: superlative,
                buildDataEnabled: location_config.enable_build_data,
                buildDataExpanded: buildDataWidgetClicked,
              });
          }
          if (pluginLocation === "srp" && typeof handleSnapAfterAnalytics === "function") {
            handleSnapAfterAnalytics({ forceSend: true, type: snapAfterType, vin });
          }
          buildDataWidgetClicked = false;
        }

        if (placeholder) {
          window.open(url, "_blank");
        } else if (is_mobile) {
          const closeButton = get_close_button();
          const iframe = closeButton + "<iframe id='tradepending-inline-iframe' src='" + url + "' width='100%' frameborder='0' scrolling='no' style='overflow: hidden;'></iframe>";
          launchMobileOverlay(iframe, {
            onClosed: () => {
              logEvent("badge_report_close", { vin, superlative_id, superlative });
            },
          });
        } else {
          let width = "80%";
          const height = "90%";
          if (jQuery(document).width() < 1200) width = "90%";

          jQuery.colorboxTP({
            href: url,
            iframe: true,
            fastIframe: false,
            width,
            height,
            scrolling: false,
            opacity: 0.5,
            onClosed: function () {
              iframe_height = 0;
              logEvent("badge_report_close", { vin, superlative_id, superlative });
            },
          });
        }
        // If this was triggered via the build data, don't record additional analytics since those are recorded elsewhere
        if (!e.isTrigger) {
          const key = get_superlative_name_key(superlative);
          let eventPrefix = "";
          if (autobioWidgetClicked) {
            eventPrefix = "autobio_";
          }
          postAnalytics(`moreinfo_${eventPrefix}${key}`);
          logEvent(`${eventPrefix}badge_click`, { vin, superlative_id, superlative });
          fire_ga_event("click", `mi-${eventPrefix}${key}`, autobioWidgetClicked ? "Autobio" : "Superlatives");
        }
      };

      const clickEl = el;

      clickEl.on("click", onClickHandler);
      const buildDataHtml = jQuery(clickEl).find(".tp-build-data-wrapper");
      buildDataHtml.on("click", (e) => {
        e.stopPropagation();
        e.preventDefault();
        buildDataWidgetClicked = true;
        logEvent("build_data_click", fbPayload);
        fire_ga_event("click", "bd-" + fbPayload.superlative, "Superlatives");
        return clickEl.click();
      });
    }

    function reportHiddenInsertSelector(el, selector) {
      reportErrorIfHidden(el, "hidden_insert_selector", "Superatives Inserted into hidden element ", selector);
    }

    function reportHiddenNodeSelector(el, selector) {
      reportErrorIfHidden(el, "hidden_node_selector", "Superatives Node Selector returning hidden element ", selector);
    }

    function reportErrorIfHidden(el, type, message, selector) {
      if (el.length == 0) {
        return;
      }
      if (superlative_config.status !== "working") {
        return;
      }
      if (!el.is(":visible") || el.is(":hidden")) {
        var location = is_mobile ? tpUtil.getPluginLocation() + "_mobile" : tpUtil.getPluginLocation();
        console.error("TradePending: Superlatives Error: type: " + type + ", location: " + location + " message: " + message + ", selector: " + selector);
      }
    }

    function getRootClass() {
      return `tp-superlative-root${isMobile() ? "-mobile" : ""}`;
    }

    function build_superlatives_html(superlative_info, template_html, superlatives_html, locationConfig) {
      var vin = superlative_info.fetchedVin || superlative_info.vin;
      var html;
      var clearDiv = "<div style='clear: both;'></div>";
      const variables = Object.assign({}, superlative_info, locationConfig.variables);
      if (superlative_info.placeholder) {
        html =
          "<div style='text-align:center'><button class='tp-superlative-data' style='padding: 10px; color: #fff; background-color: #f76841; font-size:large;' data-vin='" +
          vin +
          "' data-placeholder=true>Add a superlative</button><div style='font-size:large; font-style:italic'>Only dealers can see this button</div><div>";
      } else if (superlative_info.superlative != "") {
        if (template_html) html = clearDiv + template_html;
        else html = clearDiv + superlatives_html(variables);
      }
      html = `<div class="${getRootClass()}"><div class="tp-superlative-data"></div>${html}</div>`;
      var outputElem = jQuery(html);
      var others = superlative_info.details_superlative;
      if (others && others.length > 1) {
        var icons = outputElem.find(".tp-superlative-other-superlatives");
        var count = others.length - 1;
        if (superlative_info.details.manager_special) {
          count++;
        }
        icons.append("+" + count);
        icons.css("display", "flex");
        icons.attr("title", "This vehicle has " + count + " more interesting facts.  Click here to learn more!");
        outputElem.find(".tp-superlative-container").attr("data-count", count);
      } else {
        outputElem.find(".tp-superlative-container").attr("data-count", 1);
      }
      return outputElem;
    }

    function draw_superlative_box(idx, superlative_info, outputContainer, superlatives_html, location_config, template_html) {
      var element_container = outputContainer[superlative_info.vin];
      if (!element_container) return;
      var vin = superlative_info.fetchedVin || superlative_info.vin;
      var outputElem = build_superlatives_html(superlative_info, template_html, superlatives_html, location_config);

      var insert_selector = location_config.selector;

      var addToElement = jQuery(element_container).find(insert_selector);
      var insert_failed = false;
      reportHiddenInsertSelector(addToElement, insert_selector);
      if (addToElement.length == 0) {
        insert_failed = true;
      } else {
        var missingFromParent = addToElement.parent().find(`.${getRootClass()} .tp-superlative-data[data-vin=${vin}]`).length == 0;
        var missingChild = addToElement.find(`.${getRootClass()} .tp-superlative-data[data-vin=${vin}]`).length == 0;

        if (location_config.insert_method == "after" && missingFromParent) {
          addToElement.after(outputElem);
        } else if (location_config.insert_method == "before" && missingFromParent) {
          addToElement.before(outputElem);
        }

        if (location_config.insert_method == "prepend" && missingChild) {
          addToElement.prepend(outputElem);
        } else if ((!location_config.insert_method || location_config.insert_method == "append") && missingChild) {
          addToElement.append(outputElem);
        }
      }
      finalize_inserted_element(location_config, superlative_info, element_container, outputElem);
      if (!insert_failed && tpUtil.getPluginLocation() == "vdp") {
        fireSuperlativeVisitAnalytics(superlative_info.superlative);
      }
      return insert_failed;
    }

    function finalize_inserted_element(location_config, superlative_info, element_container, outputElem) {
      if (superlative_info.superlative) {
        var vin = superlative_info.fetchedVin || superlative_info.vin;
        var dealerId = superlative_info.dealerId || dealer_id;
        var moreInfoEl = jQuery(element_container).find(".tp-superlative-data").attr("data-vin", vin).attr("data-dealer-id", dealerId).attr("data-superlative-id", superlative_info.superlative_id);
        if (!moreInfoEl.data("superlative")) {
          moreInfoEl.attr("data-superlative", superlative_info.superlative);
        }
        if (moreInfoEl?.length) {
          trackSuperlativeView(moreInfoEl[0]);
        }
      }

      setup_superlative_click(outputElem, location_config, superlative_info);
      add_superlative_sidecar(superlative_info, outputElem);
    }

    function fireSuperlativeVisitAnalytics(superlative) {
      if (process.env.DEBUG_ENABLED) console.log("Firing superlative visit analytics for superlative: ", superlative);
      var fromSRP = isFromSRP() && tpUtil.getPluginLocation() == "vdp";
      if (fromSRP) {
        var key = get_superlative_name_key(superlative);
        fire_ga_event("visit", "vdp_superlative_" + key, "Superlatives");
        postAnalytics("supervisit_" + key);
      }
    }

    // End of SNAP Superlatives

    // Start of snap-after tracking
    interface SnapAfterOptions {
      forceSend?: boolean;
      vin?: string;
      type?: "vdp" | "srp-superlative" | "srp-autobio";
    }
    async function handleSnapAfterAnalytics(options: SnapAfterOptions = {}) {
      if (!vdp_tracking_config) {
        return;
      }
      const pluginLocation = tpUtil.getPluginLocation();
      if (pluginLocation != "vdp" && !options?.forceSend) {
        return;
      }

      if (typeof vdp_tracking_config !== "object") {
        if (process.env.DEBUG_ENABLED) console.log("vdp_tracking_config not found");
        return;
      }

      if (options?.vin) {
        const vin = options?.vin;
        _sendSnapAfterEvent({ dealerId: dealer_id, vin, eventType: options?.type });
        return;
      }
      if (DDC_API) {
        DDC_API.subscribe("vehicle-shown-v1", (ev) => {
          const vin = ev.payload?.vin;
          _sendSnapAfterEvent({ dealerId: dealer_id, vin, eventType: options?.type });
        });

        return;
      }
      var vin_selector;
      var node_selector;
      if (vdp_tracking_config.use_superlatives_config) {
        if (typeof superlative_config == "object" && superlative_config.locations && superlative_config.locations[pluginLocation]) {
          var desktop_config = superlative_config.locations[pluginLocation];
          var mobile_config = superlative_config.locations[pluginLocation + "_mobile"];
          vin_selector = desktop_config.vin_selector;
          node_selector = desktop_config.node_selector;
          if (isMobile() && mobile_config) {
            if (mobile_config.vin_selector) {
              vin_selector = mobile_config.vin_selector;
            }
            if (mobile_config.node_selector) {
              node_selector = mobile_config.node_selector;
            }
          }
        } else {
          if (process.env.DEBUG_ENABLED) console.log("snapafter survey configured to use superlatives but superlative_config or plugin location is not defined");
          return;
        }
      } else {
        vin_selector = vdp_tracking_config.vin_selector;
        node_selector = vdp_tracking_config.node_selector;
      }

      if (!vin_selector || !node_selector) {
        if (process.env.DEBUG_ENABLED) console.log("vin_selector and node_selector are not set");
        return;
      }

      var container_node = jQuery(node_selector);

      async function getVins() {
        const vinList: string[] = [];
        let interval = 0;
        const sleepTime = 500;

        while (vinList.length === 0 && interval < 5) {
          await tpUtil.sleep(sleepTime * interval++);
          container_node.each(function (_index: number, element) {
            const result = getDataFromElement(element, vin_selector, ExtractedItem.VIN);
            if (result) vinList.push(result);
          });
        }
        return vinList;
      }

      const vin_list = await getVins();

      if (vin_list.length == 0) {
        console.log("No VIN found");
      } else if (vin_list.length > 1) {
        console.log("Found more than 1 VIN on VDP");
      }
      const vin = vin_list.join(",");
      _sendSnapAfterEvent({ dealerId: dealer_id, vin, eventType: options?.type });
    }

    const buildUtmObject = (): { [key: string]: string } => {
      const url = window.location.href;
      const utm = {};
      if (url.match(/utm/i)) {
        const regex = /utm_?([^=]*)=([^&]*)/gi;
        let match = regex.exec(url);
        while (!!match) {
          utm[match[1].replace(/\./g, "_")] = match[2];
          match = regex.exec(url);
        }
      }
      return utm;
    };
    async function _sendSnapAfterEvent({ dealerId, vin, eventType }: { dealerId: string; vin: string; eventType?: string }) {
      const params: { [key: string]: string } = {
        dealer_id: dealerId,
        host_url: window.location.href,
        vin,
        timestamp: String(new Date().getTime()),
      };

      if (eventType) {
        params.type = eventType;
      }
      const r = document.cookie.match(/tpa_vdp=(.*?)(?:;|$)/);
      if (r) {
        params.survey_id = r[1];
      }

      if (linkPromise) {
        await linkPromise; // guarantee cookies have been set
      }
      const autobioVdp = document.createElement("script");
      const tplink = getCookie(`tp_link`);
      if (tplink) {
        params.lid = tplink;
      }
      const queryStr = new URLSearchParams(params);

      const utm = buildUtmObject();

      for (const key of Object.keys(utm)) {
        queryStr.append(`utm[${key}]`, utm[key]);
      }

      const abUrl = `${plugin_ajax_url}/survey/${dealerId}/${vin}/vdp.js?${queryStr.toString()}`;
      autobioVdp.setAttribute("src", abUrl);
      autobioVdp.setAttribute("async", "true");
      document.body.appendChild(autobioVdp);
    }

    // End of snap-after tracking

    const search_url = window.location.protocol + es_url;
    let superlatives_installed = false;
    const is_mobile = isMobile();
    let typeahead_activated = false;
    let delete_csrf_token = false;
    let plugin_page_locale = null;
    let snap_widget_installed = false;
    let superlative_active = false;
    let vrp_ready_listener_installed = false;
    let conversion_data = null;
    let tp_analytics_session_id = null;
    let tp_analytics_session_id_is_new = false;
    let iframe_height = 0;
    let conversion_lead_id = null;
    const analytics_queue: any[] = [];
    let analytics_lock = null;
    let showing_mobile_overlay = false;

    debugMessage("Using existing jQuery version: " + jQuery.fn.jquery);

    function receiveMessage(e) {
      const event = e.originalEvent;
      if (event.origin !== plugin_ajax_url && event.origin !== AA_BASE_URL && !event.origin?.includes("localhost")) {
        if (event.origin.substring(event.origin.indexOf(":")) !== plugin_ajax_url.substring(plugin_ajax_url.indexOf(":")) && event.origin.substring(event.origin.indexOf(":")) !== AA_BASE_URL.substring(AA_BASE_URL.indexOf(":"))) {
          return;
        }
      }
      let data;
      if (typeof event.data === "string") {
        data = JSON.parse(event.data);
      } else {
        data = event.data;
      }
      debugMessage("receiveMessage: ", data);

      debugMessage("Show close button?: ", data.show_close_button);

      if (typeof data.show_close_button === "boolean") {
        const closeButton = ".tradepending-close-affix";
        if (data.show_close_button) {
          jQuery(closeButton).show();
        } else {
          jQuery(closeButton).hide();
        }
      }
      if (data.height !== undefined) {
        const selector = data.selector || "iframe#tradepending-inline-iframe";
        debugMessage("resizing for selector: ", selector);

        if (is_mobile) {
          // it has become exceedingly difficult to consistently get the window height of the parent from within the iframe
          // so get it here, and use the min value to set the iframe height.
          let pixels = data.height;
          if (typeof pixels == "string") {
            pixels = Number(pixels.replace("px", "").trim());
          }

          const isLanding = tpUtil.isLandingOrInline();
          const height = isLanding ? `${Math.min(pixels, Math.floor(window.innerHeight * 0.65))}px` : pixels;
          debugMessage("receiveMessage is updating inline-iframe height to: " + height);
          jQuery(selector).css("height", height);

          if (data.colorbox) {
            jQuery.colorboxTP.resize({ height });
          }
        } else {
          debugMessage("receiveMessage is updating height to: " + data.height);
          if (iframe_height < data.height || data.force_height) {
            const height = `${data.height}px`;
            iframe_height = data.height;
            jQuery(selector).css("height", height);

            if (data.colorbox) {
              jQuery.colorboxTP.resize({ height });
            }
          }
        }
      }

      if (data.asc_event !== undefined) {
        switch (data.asc_event?.action) {
          case "form_start":
            fire_asc_formstart_events(data.asc_event?.product, data.asc_event.vehicle);
            break;
          case "form_submit":
            fire_asc_cta_event({ product: data.asc_event?.product, actionResult: "success", elementText: "submit", vehicle: data.asc_event.vehicle });
            fire_asc_formsubmission_events(data.asc_event?.product, data.asc_event.vehicle);
            break;
        }
      }
      if (data.ga_event !== undefined) {
        fire_ga_event(data.ga_event.action, data.ga_event.label, data.ga_event?.product || "SNAP");
        if (data.ga_event?.product == "SNAP" || data.ga_event?.product == "Trade") {
          if (data.ga_event?.eventName) {
            fire_ga_event(data.ga_event.eventName, undefined, undefined, data.ga_event?.gaParams);
          }
        }
      }

      if (data.firebase_event !== undefined) {
        logEvent(data.firebase_event.action, data.firebase_event.params);
      }

      if (typeof superlative_config !== "undefined") {
        debugMessage("receiveMessage updating allow_superlative_config to" + data.allow_superlative_config);
        if (data.allow_superlative_config) {
          superlative_config.allow_config = true;
          superlative_config.user_id = data.user_id;
          superlative_config.demo = data.demo;
        } else {
          superlative_config.allow_config = false;
        }
      }
      if (data.event === "selected") {
        fire_vehicle_selected_events(data.vehicle, data.product);
      } else if (data.event === "lead-form") {
        if (plugin_config.program_oem === "fca" && typeof window.digitalData === "object" && typeof window.digitalData.newEvent === "function") {
          debugMessage("Firing FCA analytics start event");
          window.digitalData.newEvent({
            type: "CustomTagEvent",
            eventName: "start",
            eventAction: "form",
            attributes: { formDescription: "lead", formType: "vendor trade in tool", displayType: "inPage", displayFormat: "iframe", tradeInProvider: "TradePending" },
          });
        }
        if (plugin_config.program_oem === "gm" && typeof window._satellite === "object" && typeof window._satellite.track === "function") {
          debugMessage("Firing GM analytics start event on parent page");
          window._satellite.track("contact-form");
        }
      } else if (data.event === "additional-details") {
        if (plugin_config.program_oem === "gm" && typeof window._satellite === "object" && typeof window._satellite.track === "function") {
          debugMessage("Firing GM analytics additional-details event");
          window._satellite.track("additional-details");
        }
      } else if (data.event === "selected_year" && is_mobile && tpUtil.isLandingOrInline()) {
        fire_tradein_start_events("mobile_inline");
      }

      if (data.conversion !== undefined) {
        debugMessage("Received conversion event", data.conversion);
        if (window.tradependingConversionEvent !== undefined && typeof window.tradependingConversionEvent === "function") {
          window.tradependingConversionEvent(data.conversion);
        }
        if (plugin_config.program_oem === "gm" && typeof window._satellite === "object" && typeof window._satellite.track === "function") {
          debugMessage("Firing GM analytics conversion event");
          window._satellite.track("market-report");
        }
        if (plugin_config.autoleadstar_enabled && typeof window.AutoLeadStarEvent === "function") {
          debugMessage("Firing AutoLeadStar analytics conversion event", data.conversion);
          window.AutoLeadStarEvent(3810, "conversion", data.conversion);
        }
        if (plugin_config.roiq_enabled && window.trafficscore && window.trafficscore.track && typeof window.trafficscore.track.track === "function" && window.traffic_score_data && window.traffic_score_data.account_id) {
          const roiq_data = {
            firstname: data.conversion.first_name,
            lastname: data.conversion.last_name,
            email: data.conversion.email,
            phone: data.conversion.phone,
          };
          debugMessage("Firing RoiQ analytics conversion event to account: ", roiq_data);
          window.trafficscore.track.track(window.traffic_score_data.account_id, "LEAD_CONVERSION", null, roiq_data, true);
        }
        conversion_data = data.conversion;
      }
      if (data.conversion_lead_id) {
        conversion_lead_id = data.conversion_lead_id;
        // Create cookie to store lead ID for AutoAPR
        const maxage = 1 * 24 * 60 * 60;
        document.cookie = "tp_snap_lead_id=" + conversion_lead_id + "; max-age=" + maxage + ";";
      }
      if (data.conversion_lead_id !== undefined && conversion_data != null && plugin_config.program_oem === "fca" && typeof window.digitalData == "object" && typeof window.digitalData.newEvent === "function") {
        const leadId = data.conversion_lead_id + ":" + data.conversion_timestamp;
        debugMessage("Firing FCA analytics conversion event with lead_id: " + leadId + " and data: ", conversion_data);
        window.digitalData.newEvent({
          type: "CustomTagEvent",
          eventName: "submit",
          eventAction: "form",
          attributes: {
            formDescription: "lead",
            formType: "vendor trade in tool",
            leadId,
            displayType: "inPage",
            displayFormat: "iframe",
            tradeInProvider: "TradePending",
            tradeInVehicleYear: conversion_data.year,
            tradeInVehicleMake: conversion_data.make,
            tradeInVehicleModel: conversion_data.model,
            desiredVehicleYear: conversion_data.interest_vehicle_year,
            desiredVehicleMake: conversion_data.interest_vehicle_make,
            desiredVehicleModel: conversion_data.interest_vehicle_model,
          },
        });
        conversion_data = null; // clear it out so we don't double fire
      }

      if (data.aa_event) {
        debugMessage("Received AutoAPR event", data.aa_event.action);
        // Send AutoAPR events to TP Analytics
        postAnalytics(data.aa_event.action);
      }

      if (data.cta_event) {
        debugMessage("Received CTA event", data.cta_event.action);
        // e.g. epr_start, epr_submit
        postAnalytics(data.cta_event.action);
      }

      if (plugin_config.full_js_integration && data.vehicle !== undefined && data.pricing !== undefined && window.tradependingReportRun !== undefined && typeof window.tradependingReportRun === "function") {
        debugMessage("Calling js method: tradependingReportRun with vehicle: " + data.vehicle.ymmt + " and trade-in value: $" + data.pricing?.tradeid?.low + " - $" + data.pricing?.tradein?.high);
        reportInfo("feature-usage", { feature: "tradependingReportRun" });
        window.tradependingReportRun(data.vehicle, data.pricing);
      } else if (plugin_config.full_js_integration && data.vehicle !== undefined && window.tradependingVehicleSelected !== undefined && typeof window.tradependingVehicleSelected === "function") {
        debugMessage("Calling js method: tradependingVehicleSelected with vehicle: " + data.vehicle.ymmt);
        reportInfo("feature-usage", { feature: "tradependingVehicleSelected" });
        window.tradependingVehicleSelected(data.vehicle);
      }
    }

    function reportError(type, params) {
      console.error("TradePending: SNAP Report Error: type: " + type + ", location: " + tpUtil.getPluginLocation() + " params: ", params);
      console.trace();
      sendLog("error", type, params);
    }

    async function sendLog(level, type, params) {
      params.plugin_id = plugin_id;
      params.host_url = window.location.origin + window.location.pathname + encodeURIComponent(window.location.hash);
      const path = level === "info" ? "/plugin-log/" : "/plugin-error/";
      const oldPrefilter = bypassPrefilter();
      await fetch(plugin_ajax_url + path + type, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(params),
      });
      restorePrefilter(oldPrefilter);
    }

    function reportInfo(type, params) {
      sendLog("info", type, params);
    }

    async function notifyClosed() {
      maybeShowSurvey();
      debugMessage("notifyClosed called with conversion_lead_id set to: " + conversion_lead_id);
      if (conversion_lead_id) {
        await fetch("//" + plugin_server + "/close?lead_id=" + conversion_lead_id);
      }
    }

    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        notifyClosed();
      }
    });

    function bypassPrefilter() {
      if (jQuery.ajaxPrefilter) {
        const oldFilter = jQuery.ajaxPrefilter;
        jQuery.ajaxPrefilter(function (options, originalOptions, jqXHR) {
          if (options.url.indexOf("tradepending") < 0) {
            oldFilter(options, originalOptions, jqXHR);
          } else {
            const endOfJson = options && options.data && options.data.indexOf("}&");
            if (endOfJson > -1) {
              options.data = options.data.substring(0, endOfJson + 1);
            }
          }
        });
        return oldFilter;
      }
    }

    function restorePrefilter(filter) {
      if (filter) {
        jQuery.ajaxPrefilter(filter);
      }
    }

    function getDataFromElement(element, data_selector_raw, extracted_item: ExtractedItem): string {
      let data;
      let data_selector;
      let data_attribute;
      if (data_selector_raw[0] === "@") {
        data_attribute = data_selector_raw.substring(1);
        if (data_selector_raw.substring(0, 5) === "data-") {
          data = extractDataValue(jQuery(element).data(data_attribute.substring(5)), extracted_item);
        } else {
          data = extractDataValue(jQuery(element).attr(data_attribute), extracted_item);
        }
      } else {
        if (data_selector_raw.indexOf("@") > 0) {
          data_selector = data_selector_raw.substring(0, data_selector_raw.indexOf("@"));
          data_attribute = data_selector_raw.substring(data_selector_raw.indexOf("@") + 1);
        } else {
          data_selector = data_selector_raw;
        }
        const dataElement = jQuery(element).find(data_selector);
        if (data_attribute != null && data_attribute.substring(0, 5) === "data-") {
          data = null;
          dataElement.each(function (element) {
            const key = data_attribute.substring(5);
            if (dataElement.data(key) != null) {
              const temp_data = extractDataValue(dataElement.data(key), extracted_item);
              if (temp_data != null) data = temp_data;
            }
          });
        } else if (data_attribute != null) {
          const attr = dataElement.attr(data_attribute);
          if (attr) {
            data = extractDataValue(attr, extracted_item);
          } else {
            debugMessage("TradePending: Could not find vin (try a more restrictive node selector or a different vin selector) :", data_attribute, data_selector_raw, element);
          }
        } else if (dataElement.html() != null) {
          data = extractDataValue(dataElement.html(), extracted_item);
        }
      }
      return data;
    }

    function extractDataValue(data, extracted_item: ExtractedItem) {
      switch (extracted_item) {
        case ExtractedItem.VIN:
          return extractVinOrStockNumber(data, "[0-9A-Z]{17}");
        case ExtractedItem.STOCK_NUMBER:
          return extractVinOrStockNumber(data, autoapr_config?.locations[tpUtil.getPluginLocation()]?.stock_numbers_pattern);
        case ExtractedItem.PRICE:
          return extractPrice(data);
      }
    }

    function extractVinOrStockNumber(data, pattern) {
      if (!data) {
        debugMessage("No VIN / Stock Number found, the element passed to the extraction function was empty");
        return null;
      }
      let regex_result = data.match(pattern);
      if (regex_result != null) {
        return regex_result[0];
      } else {
        regex_result = data.match(pattern.toLocaleLowerCase());
        if (regex_result != null) {
          return regex_result[0].toUpperCase();
        }
        debugMessage("No VIN / Stock Number found in ", data);
        return null;
      }
    }

    function extractPrice(price) {
      if (!price) {
        debugMessage("No PRICE found, the element passed to the extraction function was empty");
        return null;
      }
      if (price.indexOf(".") > 0) {
        price = price.substring(0, price.indexOf("."));
      }
      return price.replace(/[^0-9]/g, "");
    }

    function addVdpParams(plugin, params) {
      if (typeof plugin?.ymm_selector !== "undefined" && typeof plugin?.ymm_regex !== "undefined") {
        try {
          debugMessage("Looking for VDP params using selector: " + plugin.ymm_selector + " and regex: " + plugin.ymm_regex);
          const ymm_regex = plugin.ymm_regex.replace(new RegExp(/\\\\/g), "\\");
          const ymm = jQuery(plugin.ymm_selector).text().match(ymm_regex).slice(1);
          params.vdp_ymm = ymm.join(";");
        } catch (ex) {
          reportError("general", { type: "extract_ymm", message: "Unable to extract ymm from VDP. ex: " + ex.message });
        }
      }
      if (plugin?.dealership_loc_selector || plugin?.url_lead_routing) {
        try {
          if (plugin.url_lead_routing) {
            debugMessage("Attempting automatic dealership routing with URL Regex: " + plugin.url_lead_routing);
            if (window.location.pathname.match(plugin.url_lead_routing)) {
              params.dealership_location = plugin.url_lead_routing;
              debugMessage("Found matching URL info for: " + plugin.url_lead_routing);
            }
          } else if (plugin.dealership_loc_selector) {
            debugMessage("Attempting automatic dealership routing with css selector: " + plugin.dealership_loc_selector);
            const element = jQuery(plugin.dealership_loc_selector);
            let dealership_loc;
            if (typeof plugin.dealership_loc_attribute !== "undefined") {
              debugMessage("Using dealership_loc_attribute of: " + plugin.dealership_loc_attribute);
              dealership_loc = element.attr(plugin.dealership_loc_attribute);
            } else {
              dealership_loc = element.text();
            }
            dealership_loc = dealership_loc
              .replace(/(\r\n|\n|\r)/gm, " ")
              .replace(/\s\s+/g, " ")
              .trim();
            if (dealership_loc != null && dealership_loc.length > 0) {
              debugMessage("Sending dealership location info: " + dealership_loc + " for lead routing");
              params.dealership_location = dealership_loc;
            } else {
              debugMessage("Did not find dealership location info for lead routing");
            }
          }
        } catch (ex) {
          reportError("general", { type: "extract_dealer", message: "Unable to extract dealership location from VDP. ex: " + ex.message });
          console.log(ex.stack);
        }
      }
      params.host_url = window.location.href;
      return params;
    }

    const maybeShowSurvey = () => {
      if (conversion_data?.first_name && collect_survey && !conversion_data?.email.includes("@tradepending.com")) {
        (function (t, e, s, o) {
          let n;
          let c;
          let l;
          (t.SMCX = t.SMCX || []),
            e.getElementById(o) ||
              ((n = e.getElementsByTagName(s)),
              (c = n[n.length - 1]),
              (l = e.createElement(s)),
              (l.type = "text/javascript"),
              (l.async = !0),
              (l.id = o),
              (l.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd0K52vITHjEF2LVlKcIwmy0L5HFPt4q9GbMl0Y88sq_2Bs.js"),
              c.parentNode.insertBefore(l, c));
        })(window, document, "script", "smcx-sdk");
      }
    };

    function launchContactForm(vehicle, options = { form: undefined, height: undefined, top: undefined, fixed: undefined }) {
      const plugin_location = tpUtil.getPluginLocation();
      const plugin = plugin_data[plugin_location];
      const params = {
        year: vehicle.year,
        make: vehicle.make,
        model: vehicle.model,
        trim: vehicle.trim,
        did: plugin_id,
        loc: plugin_location,
      };
      if (!vehicle.trim) {
        delete params.trim;
      }
      addVdpParams(plugin, params);
      let route = "vehicles";
      if (vehicle.model && vehicle.make && vehicle.model && vehicle.trim) {
        route = "contact";
      }
      let url = `//${plugin_server}/${route}?${jQuery.param(params)}`;
      debugMessage("Launch TradePending Overlay at: ", url);
      let width = "80%";
      if (jQuery(window).width() < 1200) width = "95%";

      if (options.form) url = url + "&contact_form=" + options.form;
      url = tpUtil.tp_decorate(url);
      url = tpUtil.referer_decorate(url);
      url = tpUtil.ga_decorate(url);
      url = tpUtil.shift_decorate(url);
      if (plugin_page_locale != null) url += "&locale=" + plugin_page_locale;

      let height;
      if (options.height) height = options.height;
      else height = "90%";
      let top;
      if (options.top) top = options.top;
      else top = "50px";
      let fixed;
      if (options.fixed) fixed = options.fixed;
      else fixed = false;

      window.addEventListener(
        "message",
        function (e) {
          if (typeof e.data === "string") {
            const payload = JSON.parse(e.data);
            if (payload.name === "update_tpa_vdp") {
              document.cookie = "tpa_vdp=" + payload.value + "; max-age=" + 90 * 24 * 60 * 60 + ";";
            }
          }
        },
        false
      );

      if (isMobile()) {
        window.open(url, "snapwindow").focus();
      } else {
        jQuery.colorboxTP({
          href: url,
          iframe: true,
          width: width,
          height: height,
          top: top,
          fixed: fixed,
          opacity: 0.5,
          onCleanup: maybeShowSurvey,
          onClosed: notifyClosed,
        });
      }

      if (typeof window._pxam === "object" && plugin_config.ddc_tracking) {
        console.log("Firing DDC step tracking event");
        window._pxam.push({
          type: "event",
          eventType: "step",
          eventSource: "trade-in-offer",
          feature: "TradePending",
          label: plugin_location,
        });
      }
    }

    function buildVehicleSelectFrame(options) {
      const params = { did: plugin_id, loc: tpUtil.getPluginLocation(), mobile_inline: true, locale: undefined, mobile: undefined, mobile_overlay: undefined };
      addVdpParams(options.plugin, params);
      if (plugin_page_locale != null) params.locale = plugin_page_locale;
      if (location.search.length > 0) {
        const incoming_params = parse_query_params();
        Object.keys(incoming_params).forEach(function (key) {
          if (key.startsWith("tpdata_")) {
            params[key.substring(7)] = decodeURIComponent(incoming_params[key]);
          }
        });
      }
      params.mobile = options.is_mobile;
      params.mobile_overlay = !!options.is_mobile_overlay;
      let url = "//" + plugin_server + "/vehicles?" + jQuery.param(params);
      // enable alternative url scheme for autoapr requests
      if (options.plugin?.url_constructor) {
        let baseUrl = options.plugin.url_constructor(options.plugin.vin);
        if (baseUrl.startsWith("//")) {
          baseUrl = window.location.protocol + baseUrl;
        }
        const parsedUrl = new URL(baseUrl);
        for (const [key, value] of Object.entries(params)) {
          if (!parsedUrl.searchParams.has(key)) {
            parsedUrl.searchParams.append(key, value);
          }
        }

        url = parsedUrl.toString();
      }
      debugMessage("iframe url: " + url);
      url = tpUtil.tp_decorate(url);
      url = tpUtil.referer_decorate(url);
      url = tpUtil.vehicle_decorate(url, options.plugin);
      url = tpUtil.ga_decorate(url);
      if (!url.startsWith("https:")) {
        url = `https:${url}`;
      }
      let close_button = "";
      if (options.show_close_button) {
        close_button = get_close_button();
      }
      let classes = "";
      if (options.classes) {
        classes = "class=" + options.classes;
      }
      return close_button + "<iframe id='tradepending-inline-iframe' " + classes + " src='" + url + "' width='100%' frameborder='0' scrolling='auto' style='overflow: hidden;' allow='otp-credentials'></iframe>";
    }

    function get_close_button() {
      return "<button id='cboxTPClose' class='tradepending-close-affix' role='button' aria-label='close'>X</button>";
    }

    function ddcCtaButtonClicked(plugin) {
      postAnalytics("cta_button");
      launchColorboxFullProcessPopup(plugin);
    }

    function launchColorboxFullProcessPopup(plugin_config) {
      const plugin_location = tpUtil.getPluginLocation();
      const plugin = plugin_data[plugin_location];
      let params = addVdpParams(plugin, {});
      params.location = plugin_location;
      if (plugin_page_locale != null) params.locale = plugin_page_locale;

      let url = "//" + plugin_server + "/snap/iframe/" + plugin_id + "?" + jQuery.param(params);
      // enable alternative url scheme for autoapr/ctas requests
      if (plugin_config?.url_constructor) {
        if (plugin_config.additional_params) {
          params = { ...params, ...plugin_config.additional_params };
        }
        const baseUrl = plugin_config.url_constructor(plugin_config.vin);
        url = `${baseUrl}${baseUrl.includes("?") ? "&" : "?"}${jQuery.param(params)}`;
      }
      url = tpUtil.tp_decorate(url);
      url = tpUtil.referer_decorate(url);
      url = tpUtil.vehicle_decorate(url, plugin_config);
      url = tpUtil.ga_decorate(url);
      let width = "80%";
      const height = "90%";
      if (jQuery(document).width() < 1200) width = "90%";

      jQuery.colorboxTP({
        href: url,
        iframe: true,
        width,
        height,
        top: "50px",
        opacity: 0.5,
        onClosed: notifyClosed,
      });
      jQuery("div#cboxTPLoadedContent").css("margin-bottom: 0px;");

      if (typeof window._pxam === "object" && plugin_config.ddc_tracking) {
        console.log("Firing DDC step tracking event");
        window._pxam.push({
          type: "event",
          eventType: "step",
          eventSource: "trade-in-offer",
          feature: "TradePending",
          label: plugin_location,
        });
      }
    }

    let ga_object = null;

    function get_ga_object() {
      if (ga_object) {
        return ga_object;
      }
      if (typeof window.ga === "function" && typeof window.ga.getAll === "function" && jQuery.isArray(window.ga.getAll())) {
        ga_object = window.ga;
      } else {
        const ga_object_name = Object.keys(window).find((key) => typeof window[key] === "function" && typeof window[key].getAll === "function" && jQuery.isArray(window[key].getAll()));
        if (ga_object_name) {
          debugMessage("TradePending found GA object under name: ", ga_object_name);
          ga_object = window[ga_object_name];
        }
      }
      if (!ga_object && typeof window.ga === "function") {
        //we don't set it to be our global ga_object since this is just the temporary ga object to hold events sent before ga fully loads
        return window.ga;
      }
      return ga_object;
    }

    function getGaTracker(requested_ga_id) {
      debugMessage("getGaTracker: requested_ga_id: " + requested_ga_id);
      const gao = get_ga_object();
      if (gao && gao.getAll && typeof gao.getAll == "function" && gao.getAll().length > 0) {
        for (let i = 0; i < gao.getAll().length; i++) {
          const maybe = gao.getAll()[i];
          if (typeof maybe === "object") {
            const maybe_ga_id = maybe.get("trackingId");
            debugMessage("Check if tracker with ga_id: " + maybe_ga_id + " = requested ID: " + requested_ga_id);
            if (requested_ga_id.toString().trim() === maybe_ga_id?.toString().trim()) {
              debugMessage("Found tracker that matches ga_id: " + requested_ga_id);
              return maybe;
            }
          }
        }
        debugMessage("No GA tracker found that matches " + requested_ga_id);
      }
      return null;
    }

    function fire_asc_cta_event({ product, actionResult, vehicle, elementText }: { product: string; actionResult: string; vehicle?: any; elementText: "submit" }) {
      const vehicleParams = formatVehicle(vehicle);
      const ctaParams = { element_text: elementText, event_action_result: actionResult };
      fire_ga_event("asc_cta_interaction", undefined, product, product, Object.assign({}, ctaParams, vehicleParams), { isAscEvent: true });
    }

    function fire_asc_formstart_events(product: string, vehicle?: any) {
      const vehicleParams = formatVehicle(vehicle);
      const formParams = { department: "sales", comm_status: "start", comm_type: "form", form_name: product, form_type: "consumer_contact" };
      fire_ga_event("asc_form_engagement", undefined, product, product, Object.assign({}, formParams, vehicleParams), { isAscEvent: true });
    }
    function fire_asc_formsubmission_events(product: string, vehicle?: any) {
      const vehicleParams = formatVehicle(vehicle);
      const formParams = { department: "sales", comm_status: "crm_update", comm_type: "form", form_name: product, form_type: "consumer_contact" };
      fire_ga_event("asc_form_submission", undefined, product, product, Object.assign({}, formParams, vehicleParams), { isAscEvent: true });
      fire_ga_event("asc_form_engagement", undefined, product, product, Object.assign({}, formParams, vehicleParams), { isAscEvent: true });
      fire_ga_event("asc_form_submission_sales", undefined, product, product, Object.assign({}, formParams, vehicleParams), { isAscEvent: true });
    }

    function formatVehicle(vehicle: any) {
      let condition = "unknown";

      switch (vehicle?.condition) {
        case VehicleCondition.USED:
          condition = "used";
          break;
        case VehicleCondition.NEW:
          condition = "new";
          break;
        case VehicleCondition.CERTIFIED:
          condition = "cpo";
          break;
      }
      return {
        item_id: vehicle?.vin,
        item_number: vehicle?.stock_number,
        item_price: vehicle?.price,
        item_condition: condition,
        item_year: vehicle?.year,
        item_make: vehicle?.make,
        item_model: vehicle?.model,
        item_variant: vehicle?.trim,
      };
    }

    function fire_ga_event(action: string, label?: string, category?: string, product?: string, eventParams?: {}, meta?: { isAscEvent?: boolean }) {
      let msg;
      if (process.env.DEBUG_ENABLED) {
        msg = "fire_ga_event: " + action;
        if (label != null) msg += " --> " + label;
      }
      if (!window.gtag && window.dataLayer) {
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag("js", new Date());
        if (plugin_config.ga4_tracker_id) {
          const ids = plugin_config.ga4_tracker_id.split(",").map((s) => s.trim());
          for (const id of ids) {
            window.gtag("config", id);
          }
        }
      }

      if (window.gtag) {
        let translatedCategory = category || product;
        switch (category) {
          case "SNAP":
            translatedCategory = "Trade";
            break;
          case "Superlatives":
            translatedCategory = "Badges";
            break;
        }

        const isAscEvent = meta?.isAscEvent;
        const event_message: string = isAscEvent ? action : "tp_" + action;
        const eventData: any = Object.assign({}, eventParams, {
          company: "TradePending",
          event_action: action,
          event_label: label,
          event_category: translatedCategory,
          event_owner: "tradepending",
        });
        if (product) {
          eventData.product = product;
        }

        // If a site is using GTM to pull down gtag.js, they probably never have configured the gtag function. So, an explicit tracker id is needed.
        if (plugin_config.ga4_tracker_id) {
          const ids = plugin_config.ga4_tracker_id.split(",").map((s) => s.trim());
          eventData.send_to = ids.length > 1 ? ids : ids[0];
        }
        window.gtag("event", event_message, eventData);
      }

      let oldCategory = category;
      switch (category) {
        case "Trade":
          oldCategory = "SNAP";
          break;
        case "Badges":
          oldCategory = "Superlatives";
          break;
      }
      const gao = get_ga_object();
      if (gao && gao.getAll && typeof gao.getAll == "function") {
        const submitted_ids = {};
        debugMessage("Firing GA event: " + action + ", " + label + ", " + oldCategory);
        gao.getAll().forEach(function (tracker) {
          const ga_tracker_name = tracker.get("name");
          const ga_tracking_id = tracker.get("trackingId");
          if (!submitted_ids[ga_tracking_id]) {
            submitted_ids[ga_tracking_id] = ga_tracking_id;
            gao(ga_tracker_name + ".send", "event", oldCategory, action, label);
            debugMessage(msg + " to GA ID: " + ga_tracking_id + " on tracker: " + ga_tracker_name);
            if (plugin_config.vistadash_customer != null && action == "lead-form") gao(ga_tracker_name + ".send", "event", "CPE-TradePending-Start");
            if (plugin_config.vistadash_customer != null && action == "submit") gao(ga_tracker_name + ".send", "event", "CPE-Trade-In-Complete");
          } else {
            debugMessage("Duplicate GA Tracking ID, not firing event for: " + msg + " to GA ID: " + ga_tracking_id + " on tracker: " + ga_tracker_name);
          }
        });
      } else {
        debugMessage("TradPending: Unable to discover global GA object so can't fire_ga_event");
      }
      if (action == "submit" && plugin_config.adwords_id != null && plugin_config.adwords_label != null)
        jQuery("body").append(
          '<div style="display:inline;"><img height="1" width="1" style="border-style:none;" alt="" src="//www.googleadservices.com/pagead/conversion/' +
            plugin_config.adwords_id +
            "/?label=" +
            plugin_config.adwords_label +
            '&amp;guid=ON&amp;script=0">'
        );
    }

    function launchMobileOverlay(iframe, options?: { onClosed?: () => void }) {
      if (showing_mobile_overlay) {
        return;
      }
      showing_mobile_overlay = true;

      const body = jQuery("body");
      const selector = ".tp-mobile-overlay";
      const el = jQuery("<div class='tp-mobile-overlay'>" + iframe + "</div>");

      el.addClass("tp-mobile-overlay-slidedown");
      el.appendTo(body);
      el.css("overflow", "scroll");
      body.css("overflow", "hidden");

      const setIframeHeight = () => {
        const dom = el.get(0);
        let iframeEl = dom;
        if (dom?.tagName !== "IFRAME") {
          iframeEl = dom.querySelector("iframe");
        }

        if (iframeEl) {
          iframeEl.style.height = window.innerHeight + "px";
        }
      };
      setIframeHeight();
      window.addEventListener("resize", setIframeHeight); // resize can occur when mobile URL bar is shown/hides

      jQuery(".tradepending-close-affix").click((event) => {
        el.addClass("tp-mobile-overlay-slideup");
        setTimeout(() => {
          jQuery(selector).remove();
          jQuery(".tradepending-close-affix").remove();
          body.css("overflow", "visible");
          notifyClosed();
          if (options?.onClosed) {
            options.onClosed();
          }
          showing_mobile_overlay = false;
        }, 400);
        window.removeEventListener("resize", setIframeHeight);
      });
    }

    function placeholder() {
      const placeholderSupport = "placeholder" in document.createElement("input");
      if (!placeholderSupport) {
        //This browser does not support the placeholder attribute
        //use javascript instead
        jQuery("#tradepending-vehicle-typeahead")
          .focus(function () {
            const input = jQuery(this);
            if (input.val() === input.attr("placeholder")) {
              input.val("");
              input.removeClass("placeholder");
            }
          })
          .blur(function () {
            const input = jQuery(this);
            if (input.val() === "" || input.val() === input.attr("placeholder")) {
              input.addClass("placeholder");
              input.val(input.attr("placeholder"));
            }
          })
          .blur()
          .parents("form")
          .submit(function () {
            jQuery(this)
              .find("[placeholder]")
              .each(function () {
                const input = jQuery(this);
                if (input.val() === input.attr("placeholder")) {
                  input.val("");
                }
              });
          });
      }
    }

    function isFromSRP() {
      const path = document.referrer && document.referrer.split("/")[3];
      const srp = plugin_location_configs["srp"];
      let match = false;
      if (srp && path) {
        const regexes = [srp.url_regex, srp.custom_url_regex];
        for (let index = 0; index < regexes.length; index++) {
          const regex = regexes[index];
          match = !!path.match(new RegExp(regex, "i"));
          if (match) {
            break;
          }
        }
        if (!match && plugin_config.default_plugin_location) {
          match = !!findPluginLocation(path)?.location;
        }
      }
      return match;
    }

    function setCookiesOnSuccess(response) {
      for (const c of response) {
        const cookie = c.name + "=" + c.value + "; max-age=" + c.maxage + "; host=" + c.host + "; path=" + c.path + ";";
        document.cookie = cookie;
      }
    }

    /** Post analytics to firebase (via logEvent) and tp-hit analytics */
    function postAnalytics(action: string | null, params?: any) {
      analytics_queue.push(action);
      dequeueAnalytics();

      if (action && !action.startsWith("moreinfo_") && !action.startsWith("superlative_")) {
        logEvent(action, params);
      }
    }

    // Causes us to fire analytics events one at a time in sequence
    async function dequeueAnalytics() {
      if (analytics_queue.length === 0 || analytics_lock) {
        return;
      }

      const action = analytics_queue.shift();
      analytics_lock = fireAnalytics(action);
      await analytics_lock;
      analytics_lock = null;
      return dequeueAnalytics();
    }

    function fireAnalytics(action) {
      return new Promise<void>(async (resolve, reject) => {
        const plugin_location = tpUtil.getPluginLocation();
        let url = plugin_ajax_url + "/tp-hit?dealer_id=" + dealer_id + "&plugin_id=" + plugin_id + "&location=" + plugin_location;
        if (action != null) {
          url += "&action=" + action;
        }
        url = tpUtil.tp_decorate(url);
        const parser = document.createElement("a");
        parser.href = window.location.href;
        const host_url = parser.protocol + "//" + parser.host + "/";
        url += "&host_url=" + encodeURIComponent(host_url);
        try {
          const response = await fetch(url);
          const data = await response.json();
          setCookiesOnSuccess(data);
        } catch (ex) {
          console.error(ex);
        } finally {
          resolve();
        }
        debugMessage("Firing tp analytics event: ", action, plugin_location, dealer_id);
        if (plugin_config.ddc_tracking && action === "typeahead" && typeof window._pxam === "object") {
          debugMessage("Firing DDC start tracking event");
          window._pxam.push({
            type: "event",
            eventType: "start",
            eventSource: "trade-in-offer",
            feature: "TradePending",
            label: plugin_location,
          });
        }
      });
    }

    function fire_snap_install_event() {
      postAnalytics(null);
      fire_shift_digital_event("tradeInImpression");
    }

    const SHIFT_OEMS = ["subaru", "toyota", "lexus", "fca", "gm"];
    function fire_shift_digital_event(event) {
      if (typeof window.sd === "function" && SHIFT_OEMS.includes(plugin_config.program_oem)) {
        const data = {
          tradeInSessionId: tp_analytics_session_id,
          tradeInProvider: "tradepending",
          events: event,
        };
        debugMessage("TradePending: Fire shift tradeInImpression event", data);
        window.sd("dataLayer", data);
        window.sd("send");
      }
    }

    function fire_snapoffer_autostart_event() {
      postAnalytics("so_autostart_impression");
    }

    function fire_tradein_start_events(initiator = undefined) {
      fire_ga_event("initiated", initiator, "SNAP");
      fire_ga_event("asc_retail_process", initiator, "SNAP", "SNAP", { flow_name: "trade", flow_outcome: "start" }, { isAscEvent: true });
      if (typeof window.sd === "function" && SHIFT_OEMS.includes(plugin_config.program_oem)) {
        const data = {
          tradeInSessionId: tp_analytics_session_id,
          tradeInProvider: "tradepending",
          events: "tradeInStart",
        };
        debugMessage("TradePending: Fire shift tradeInStart event", data);
        window.sd("dataLayer", data);
        window.sd("send");
      }
    }

    function fire_vehicle_selected_events(vehicle, product = "Trade") {
      fire_ga_event("selected", vehicle.ymmt, product);
      const vehicleParams = formatVehicle(vehicle);
      fire_ga_event("asc_retail_process", vehicle.ymmt, product, product, Object.assign({ flow_name: "trade", flow_outcome: "vehicle selected" }, vehicleParams), { isAscEvent: true });
      logEvent("selected", { ...vehicle, product });

      if (typeof window.sd === "function" && SHIFT_OEMS.includes(plugin_config.program_oem)) {
        const data = {
          tradeInSessionId: tp_analytics_session_id,
          tradeInProvider: "tradepending",
          tradeInYear: vehicle.year,
          tradeInMake: vehicle.make,
          tradeInModel: vehicle.model,
          events: "tradeInVehicleIndicated",
        };
        debugMessage("TradePending: Fire shift tradeInVehicleIndicated event", data);
        window.sd("dataLayer", data);
        window.sd("send");
      }
    }

    function fire_autoapr_start_events(config) {
      const { ga_category_name, url_constructor, vin } = config;
      const event_action = "Button Click";
      const event_category = `${event_action} - AutoAPR ${ga_category_name}`;
      const event_label = vin ? url_constructor(vin) : url_constructor();
      fire_ga_event(event_action, event_label, event_category);
      fire_asc_cta_event({ product: "Payments", vehicle: { vin }, elementText: config.cta_button_text, actionResult: "open" });
      logEvent("aa_cta_click", { cta: config.analytics?.cta });
    }

    function parse_query_params() {
      return tpUtil.parse_query_params(window.location.search);
    }

    function findMatchingLocation(path) {
      let location;
      if (plugin_config.apply_to_path_and_query) {
        path = path + window.location.search;
      }
      for (const key in plugin_location_configs) {
        const location_config = plugin_location_configs[key];

        if (typeof location_config.url_regex !== "undefined" && path.match(new RegExp(location_config.url_regex, "i"))) {
          location = key;
          break;
        }
        if (typeof location_config.custom_url_regex !== "undefined" && path.match(new RegExp(location_config.custom_url_regex, "i"))) {
          location = key;
          break;
        }
      }
      return location;
    }

    function findPluginLocation(path) {
      let location;
      //if we should use the query string, first check for a match
      if (plugin_config.apply_to_path_and_query) {
        location = findMatchingLocation(path);
      }
      //if that didn't find anything, then look to see if its the root page
      if (!location) {
        const noQuery = !plugin_config.apply_to_path_and_query;
        const emptyPath = path === "" && (noQuery || window.location.search === "");
        const defaultMatch = path !== "" && path.match(/^($|index\.\w{2,4}|default\.\w{2,4})/i);
        if (typeof plugin_location_configs["home"] !== "undefined" && (emptyPath || defaultMatch)) {
          location = "home";
        } else {
          location = findMatchingLocation(path);
        }
      }
      let using_default_plugin_location = false;
      if (location == null) {
        if (plugin_location_configs[plugin_config.default_plugin_location] != null) {
          location = plugin_config.default_plugin_location;
          using_default_plugin_location = true;
        } else if (typeof window.tradepending_location !== "undefined" && typeof plugin_location_configs[window.tradepending_location] !== "undefined") {
          location = window.tradepending_location;
        } else if (typeof window.requested_location !== "undefined" && typeof plugin_location_configs[window.requested_location] !== "undefined") {
          location = window.requested_location;
        }
      }
      return { location, using_default_plugin_location };
    }

    function setupAccessibility() {
      let input;
      const inputel = jQuery("#trade-pending-widget");
      if (inputel) {
        inputel.attr("aria-live", "polite");
      }
      const titleEl = jQuery("#tradepending-panel h1,h2,h3,.title").first();
      if (titleEl) {
        const text = titleEl.text();
        input = jQuery("#tradepending-vehicle-typeahead");
        if (input) {
          if (text) {
            input.attr("aria-label", text);
          } else {
            input.attr("aria-label", "Value your trade");
          }
        }
      } else {
        input = jQuery("#tradepending-vehicle-typeahead");
        if (input) {
          input.attr("aria-label", "Value your trade");
        }
      }
      const image = jQuery("#tp-poweredby, #tradepending-poweredby");
      if (image) {
        image.attr("alt", "Trade Pending Logo");
      }
      const imageContainer = jQuery(".tradepending-poweredby-container");
      if (imageContainer) {
        imageContainer.attr("aria-label", "Trade Pending logo");
      }
      const colorboxButton = jQuery("button#cboxTPPrevious,button#cboxTPNext,button#cboxTPSlideshow");
      if (colorboxButton) {
        colorboxButton.attr("aria-hidden", "true");
        colorboxButton.attr("aria-label", "Unused Colorbox button");
        colorboxButton.attr("style", "display:none");
      }
    }

    function preventInputHighjacks() {
      if (plugin_config.website_vendor_name == "DealerInspire") {
        jQuery("#tradepending-input input").on("focus keydown", function (event) {
          if (typeof window.$ae == "function") {
            // Deal with AudioEye highjacking the space key
            window.$ae("#tradepending-input input").off("keydown");
            window.$ae("#tradepending-input input").on("keydown", function (e) {
              if (e.keyCode === 13) {
                e.preventDefault();
                this.click();
              }
            });
          }
        });
      }
    }

    function isBlockedStagingSite(product_status) {
      if (product_status == "staging" && plugin_config.staging_url) {
        const parser = document.createElement("a");
        parser.href = plugin_config.staging_url;
        if (parser.hostname === window.location.hostname && (parser.pathname.length <= 1 || window.location.pathname.startsWith(parser.pathname))) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    function insert_default(plugin, widget_html, using_default_plugin_location, retries) {
      debugMessage("insert_default for attempt", retries);
      const plugin_location = tpUtil.getPluginLocation();
      let widget_css_selector = "#tradepending-plugin-container";
      if (is_mobile && typeof plugin.mobile_css_selector !== "undefined") widget_css_selector = plugin.mobile_css_selector;
      else if (typeof plugin.css_selector !== "undefined") widget_css_selector = plugin.css_selector;

      const widget_element = jQuery(widget_css_selector);
      let widget_insert_method = "append";
      if (is_mobile && typeof plugin.mobile_insert_method !== "undefined") widget_insert_method = plugin.mobile_insert_method;
      else if (typeof plugin.insert_method !== "undefined") widget_insert_method = plugin.insert_method;

      debugMessage("Using plugin: " + plugin_location + " and insert method: " + widget_insert_method + " on element: ", widget_element[0]);
      if (using_default_plugin_location && widget_element.length > 0) {
        debugMessage("Inserting using default plugin location");
      }
      if (widget_element.length > 0) {
        if (is_mobile && tpUtil.isLandingOrInline()) {
          widget_html = buildVehicleSelectFrame({ is_mobile, plugin });
        } else if (widget_html.includes("<iframe")) {
          widget_html = tpUtil.add_utm_params_to_iframe(widget_html, false);
        }
        if (plugin_config.reinstall_location_change) {
          jQuery("#tradepending-container").remove();
        }

        apply_insert_method(widget_element, widget_insert_method, widget_html);
        snap_widget_installed = true;
        fire_snap_install_event();

        if (widget_element.length > 1 && !plugin.allow_multiple) {
          reportError("selector", { type: "select_multiple", location: plugin_location });
        }
        if (is_mobile && tpUtil.isLandingOrInline()) {
          const iframe = document.getElementById("tradepending-inline-iframe");
          if (iframe) {
            iframe.onload = function () {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
            };
          }
        }
      } else if (!using_default_plugin_location) {
        console.error("TradePending: TradePending SNAP selector not found: " + widget_css_selector);
        if (retries < 3) {
          debugMessage("Retrying install, after attempt", retries);
          setTimeout(() => {
            insert_default(plugin, widget_html, using_default_plugin_location, retries + 1);
          }, retries * 1500);
        }
      }
    }

    async function installSnapDdcWidget(plugin, widget_html) {
      return new Promise<void>(function (resolve, reject) {
        try {
          const css_selector = tpUtil.getCssSelector(plugin);
          DDC_API.subscribe("page-load-v1", (ev) => {
            DDC_API.insert(css_selector, (elem, meta) => {
              try {
                if (snap_widget_installed) {
                  console.log("TradePending: skipping second call to installSnapDdcWidget since the widget is already installed");
                } else {
                  snap_widget_installed = true;
                  widget_html = "<div>" + widget_html + "</div>";
                  debugMessage("inserting SNAP via ddc api: " + css_selector);
                  DDC_API.append(elem, jQuery(widget_html).get(0));
                }
              } catch (err) {
                console.log("error calling DDC.append", err);
              }
              fire_snap_install_event();
              resolve();
            });
          });
        } catch (err) {
          reject(err);
        }
      });
    }

    async function installSnapDdcLandingPage(plugin, widget_html) {
      return new Promise<void>(function (resolve, reject) {
        try {
          DDC_API.subscribe("page-load-v1", (ev) => {
            if (tpUtil.isDdcPageNameMatch(ev.payload.pageName, [DDC_LANDING_PAGE_LOCATIONS.snapOffer.pageName, DDC_LANDING_PAGE_LOCATIONS.snap.pageName])) {
              DDC_API.insert("content", (elem, meta) => {
                try {
                  if (snap_widget_installed) {
                    console.log("TradePending: skipping second call to installSnapDdcLandingPage since the widget is already installed");
                  } else {
                    snap_widget_installed = true;
                    if (is_mobile && tpUtil.isLandingOrInline()) {
                      widget_html = buildVehicleSelectFrame({ is_mobile, plugin });
                    } else {
                      widget_html = "<div>" + widget_html + "</div>";
                    }
                    debugMessage(`inserting SNAP via ddc api on landing page: ${ev.payload.pageName}`);
                    DDC_API.append(elem, jQuery(widget_html).get(0));
                  }
                } catch (err) {
                  console.log("error calling DDC.append", err);
                }
                fire_snap_install_event();
                resolve();
              });
            } else {
              debugMessage(`installSnapDdcLandingPage: pageName ${ev.payload.pageName} did not match expected page names.`);
              resolve();
            }
          });
        } catch (err) {
          reject(err);
        }
      });
    }

    function enableDisplay() {
      if (is_mobile) {
        jQuery(document)
          .bind("cboxTP_open", function () {
            jQuery("html").css({ overflow: "hidden" });
          })
          .bind("cboxTP_closed", function () {
            jQuery("html").css({ overflow: "" });
          });
        jQuery("#tradepending-select").css("display", "block");
        jQuery(".tradepending-mobile").css("display", "block");
        jQuery("#tradepending-input").css("display", "none");
        jQuery(".tradepending-hide-mobile").css("display", "none");
      } else {
        jQuery("#tradepending-select").css("display", "none");
        jQuery(".tradepending-mobile").css("display", "none");
        jQuery("#tradepending-input").css("display", "block");
        jQuery(".tradepending-hide-mobile").css("display", "block");
      }
    }

    function installReloadListeners(plugin_config) {
      debugMessage("SNAP setup SRP reload listeners");
      vrp_ready_listener_installed = true;
      if (plugin_config.website_vendor_name == "DealerInspire") {
        jQuery("body").on("vrp-ready vrp-ajax-complete", function (event) {
          debugMessage("DealerInspire SRP reload");
          install();
        });
      }
      if (plugin_config.website_vendor_name == "Sokal") {
        jQuery(document).on("infiniteScrollFinished ws-search-results", function (event) {
          debugMessage("Sokal SRP reload " + event.type);
          jQuery("div#tradepending-container").remove();
          install();
        });
      }
    }

    function shouldInstallProduct(product_config, product_location_config, is_aa?) {
      const active_statuses = ["live", "needs_qa", "partial", "canceled", "staging", "working"];
      const product = is_aa ? "AutoApr" : "CTAs";
      if (product_config && product_config?.locations && product_config?.locations[tpUtil.getPluginLocation()]) {
        if (!product_location_config || product_location_config.disabled || !active_statuses.includes(product_config.status) || !product_location_config?.templates) {
          if (document.cookie.match(/tp_test_product=true/) == null) {
            return false;
          }
        }
        if (isBlockedStagingSite(product_config.status)) {
          debugMessage(`${product} not displaying because it's a staging site that doesn't match the configured staging url: ${plugin_config.staging_url}`);
          return false;
        }
        try {
          if (is_aa && !product_config.dealer_id) {
            console.error("TradePending: Error: Missing Auto APR dealer code");
            return false;
          }
          return true;
        } catch (err) {
          console.log(`error installing ${product} widgets `, err);
        }
      }
      return false;
    }

    let debouncedInstallCtas;
    const installAutoApr = async () => {
      const aa_location_config = getAutoAprLocationConfig();
      if (shouldInstallProduct(autoapr_config, aa_location_config, true)) {
        try {
          if (aa_location_config.custom_css) {
            addCssToHead(`aa-location-${tpUtil.getPluginLocation()}`, aa_location_config.custom_css);
          }
          installAutoAprBannerOrLandingPage(aa_location_config);
          installAutoAprFloatingButton(aa_location_config);
        } catch (err) {
          console.log("error installing AutoAPR widgets ", err);
        }
      }
    };

    const build_wrapper = (uniqueClass, widgetHtml) => {
      const wrapper = document.createElement("div");
      wrapper.className = `${uniqueClass}`;

      wrapper.innerHTML = widgetHtml;
      return wrapper as HTMLElement;
    };

    async function shouldShowDdcCta(locationConfig) {
      if (!DDC_API) {
        return false;
      }
      const dealerDDCInfo = await DDC_API.utils.getDealerData();
      const vehicleDDCInfo = await DDC_API.utils.getVehicleData();
      const dealerRegex = locationConfig?.autonation_dealer_regex;
      let displayAutoNationCTA = false;
      if (!!dealerRegex) {
        const displayAutoNationCtaVdp = tpUtil.getPluginLocation() === "vdp" && dealerDDCInfo?.dealershipName === AUTONATION_DEALERSHIP_NAME && vehicleDDCInfo.some((vehicleInfo) => vehicleInfo.accountId.match(new RegExp(dealerRegex)));
        displayAutoNationCTA = displayAutoNationCtaVdp || tpUtil.getPluginLocation()?.startsWith("srp");
      }
      const displayCTA = dealerDDCInfo?.dealershipName !== AUTONATION_DEALERSHIP_NAME || displayAutoNationCTA;
      if (!displayCTA) {
        debugMessage("Not inserting AutoNation VDP CTA");
      }
      return displayCTA;
    }

    async function installCtas() {
      debugMessage("install installCtas()");
      let template_keys: string[] = [];
      const plugin_location = tpUtil.getPluginLocation();
      const snapPlugin = plugin_data[plugin_location];
      const shouldInstallTradeDDCCallToAction = DDC_API && snapPlugin?.use_ddc_api && snapPlugin?.use_ddc_api_cta;
      const shouldInstallTradeCallToAction = snapPlugin?.cta_css_selector || shouldInstallTradeDDCCallToAction;
      if (shouldInstallTradeCallToAction) {
        template_keys.push("trade_cta");
      }
      const aa_location_config = getAutoAprLocationConfig();
      if (shouldInstallProduct(autoapr_config, aa_location_config)) {
        template_keys = template_keys.concat(Object.keys(AA_PRODUCT_MAP).filter((key) => key.match(/cta/) && aa_location_config.templates[key]));
      }
      const ctas_location_config = ctas_config?.locations[plugin_location];
      if (shouldInstallProduct(ctas_config, ctas_location_config)) {
        template_keys = template_keys.concat(Object.keys(CTAS_MAP).filter((key) => ctas_location_config.templates[key]));
        if (ctas_location_config?.custom_css) {
          addCssToHead(`ctas-location-${tpUtil.getPluginLocation()}`, ctas_location_config.custom_css);
        }
      }
      const location_config = plugin_location_configs[tpUtil.getPluginLocation()];
      if (location_config?.ctas_order) {
        template_keys.sort((a, b) => (a === "combined_cta" || b === "combined_cta" ? 0 : location_config.ctas_order.indexOf(a) - location_config.ctas_order.indexOf(b)));
      }
      for (const template_key of template_keys) {
        if (template_key === "trade_cta") {
          if (!shouldInstallTradeDDCCallToAction && is_mobile) {
            snapPlugin.cta_css_selector = snapPlugin.cta_mobile_css_selector || snapPlugin.cta_css_selector;
            snapPlugin.cta_insert_method = snapPlugin.cta_mobile_insert_method || snapPlugin.cta_insert_method;
          }
          await installCallToAction({ ...snapPlugin, cta_unique_class: "snap-cta" }, TP_CTA_SELECTOR, shouldInstallTradeDDCCallToAction, installCallToActionDefault);
        } else {
          const isAutoApr = AA_PRODUCT_MAP.hasOwnProperty(template_key);
          const plugin_config = isAutoApr ? aa_location_config : ctas_location_config;
          const template_config = plugin_config.templates[template_key];
          if (template_config) {
            const cta_unique_class = `${template_key}_button`;
            const productMap = isAutoApr ? AA_PRODUCT_MAP : CTAS_MAP;
            if (shouldInstallBannerOrCta(template_config)) {
              if (template_config?.widget_css) {
                addCssToHead(template_config.standardized_cta ? "standardized_cta" : template_key, template_config.widget_css);
              }
              if (is_mobile) {
                template_config.node_selector = template_config.mobile_node_selector || template_config.node_selector;
                template_config.selector = template_config.mobile_selector || template_config.selector;
                template_config.insert_method = template_config.mobile_insert_method || template_config.insert_method;
                template_config.vin_selector = template_config.mobile_vin_selector || template_config.vin_selector;
                template_config.stock_number_selector = template_config.mobile_stock_number_selector || template_config.stock_number_selector;
                template_config.price_selector = template_config.mobile_price_selector || template_config.price_selector;
              }
              const variables = template_config?.variables || {};
              const plugin = {
                cta_button_type: template_config.cta_button_type || "",
                cta_insert_selector: template_config.selector,
                cta_insert_method: template_config.insert_method,
                cta_vin_selector: template_config.vin_selector,
                cta_stock_number_selector: template_config.stock_number_selector,
                cta_price_selector: template_config.price_selector,
                cta_html_code: template_config.widget_html,
                cta_button_border_style: variables?.border_style || variables["cta-button-widget-border-style"] || "",
                cta_button_border_radius: variables?.border_radius || variables["cta-button-widget-border-radius"] || "",
                cta_button_text_color: (variables && (variables.text_color || variables["text-color"] || variables["cta-button-widget-text-color"])) || "",
                cta_button_color: (variables && (variables.background_color || variables["button-color"] || variables["cta-button-widget-background-color"])) || "",
                cta_button_class: template_config.cta_button_class || variables?.button_classes || variables["cta-button-widget-classes"] || "",
                cta_intent: productMap[template_key].cta_intent || "payment-calculator",
                cta_button_text: variables?.title || variables["cta-button-widget-title"] || variables?.inner_html || productMap[template_key].cta_title || "Calculate Your Payment",
                is_autoapr: isAutoApr,
                use_condition_check: productMap[template_key].use_condition_check,
                cta_unique_class,
                ga_category_name: productMap[template_key].product_name,
                url_constructor: (vin: string) => {
                  if (isAutoApr) {
                    return `${productMap[template_key].url}${autoapr_config.dealer_id}/${vin}`;
                  }
                  return `${window.location.protocol}//${plugin_server}/contact/form?type=${productMap[template_key].type}&did=${plugin_id}&loc=${plugin_location}&vin=${vin}`;
                },
                ctas_enabled: undefined as undefined | Set<string>,
                price_selector_disabled_new: template_config.disable_price_selector_on_new,
                price_selector_disabled_used: template_config.disable_price_selector_on_used,
                use_stock_numbers: plugin_config.use_stock_numbers,
                stock_numbers_pattern: plugin_config.stock_numbers_pattern,
                autonation_dealer_regex: plugin_config.autonation_dealer_regex,
                intermediate_url_constructor: undefined,
                template_key,
                use_ddc_api_append: plugin_config.use_ddc_api_append,
                ddc_price_override: template_config.ddc_price_override,
                ddc_year_condition_override: template_config.ddc_year_condition_override,
                analytics: { cta: template_key },
              };
              addEventContext(`${isAutoApr ? "aa" : "additional"}_cta_click`, template_key, true);
              if (template_key === "combined_cta") {
                if (!template_config.ctas) {
                  template_config.ctas = [];
                }
                plugin.ctas_enabled = new Set<string>(template_config.ctas.map((name) => `${name}_cta`));
                plugin.ctas_enabled.add("reveal_cta");
              }

              const displayCTA = await shouldShowDdcCta((isAutoApr ? autoapr_config : ctas_config)?.locations?.vdp);
              const useDdcApi = DDC_API && plugin_config.use_ddc_api;
              if (useDdcApi && plugin_config.use_ddc_api_append && displayCTA) {
                await installDDCCallToAction(plugin);
              } else {
                if (template_key === "combined_cta") {
                  await installCallToAction(plugin, template_config.node_selector, useDdcApi, installAutoAprCombinedCta);
                } else {
                  if (!useDdcApi || (useDdcApi && displayCTA)) {
                    await installCallToAction(plugin, template_config.node_selector, useDdcApi, installCallToActionDefault);
                  }
                }
              }

              const postImpressions = (type) => {
                switch (type) {
                  case "test_drive_cta":
                    postAnalytics("aa_drv_view");
                    break;
                  case "approve_cta":
                    postAnalytics("aa_apv_view");
                    break;
                  case "lease_cta":
                    postAnalytics("aa_lse_view");
                    break;
                  case "reveal_cta":
                    postAnalytics("aa_rvl_view");
                    break;
                  case "eprice_cta":
                    postAnalytics("epr_view");
                    break;
                  case "availability_cta":
                    postAnalytics("avl_view");
                    break;
                  case "test_drive_v2_cta":
                    postAnalytics("drv_view");
                    break;
                  default:
                    console.log(`TradePending: not posting analytics for unknown template_key: ${type}`);
                    break;
                }
              };

              if (template_key == "combined_cta" && plugin.ctas_enabled) {
                for (const cta of plugin.ctas_enabled.values()) {
                  postImpressions(cta);
                }
              } else {
                postImpressions(template_key);
              }
            }
          }
        }
      }
    }

    const payment_banners_installed = {};
    const installAutoAprPaymentBannerDDC = (css_selector, insertion_element, templateKey: string, install_listeners_func) => {
      return new Promise<void>(function (resolve, reject) {
        try {
          DDC_API.insert(css_selector, (elem, meta) => {
            try {
              if (payment_banners_installed[templateKey]) {
                debugMessage("TradePending: skipping second call to installAutoAprPaymentBannerDDC since the widget is already installed");
              } else {
                payment_banners_installed[templateKey] = true;
                debugMessage(`inserting AutoAPR Banner via ddc api at ${css_selector} for ${templateKey}`);
                DDC_API.append(elem, insertion_element);
                install_listeners_func();
              }
            } catch (err) {
              console.log("error calling DDC.append", err);
            }
            resolve();
          });
        } catch (err) {
          reject(err);
        }
      });
    };

    let autoaprLandingPageInstalled = false;
    const installAutoAprDdcLandingPage = (css_selector, insertion_element, templateKey: string, install_listeners_func) => {
      return new Promise<void>(function (resolve, reject) {
        try {
          DDC_API.subscribe("page-load-v1", (ev) => {
            if (tpUtil.isDdcPageNameMatch(ev.payload.pageName, [DDC_LANDING_PAGE_LOCATIONS.reveal.pageName, DDC_LANDING_PAGE_LOCATIONS.approve.pageName])) {
              DDC_API.insert("content", (elem, meta) => {
                try {
                  if (autoaprLandingPageInstalled) {
                    debugMessage("TradePending: skipping second call to installAutoAprLandingPageDDC since the widget is already installed");
                  } else {
                    autoaprLandingPageInstalled = true;
                    debugMessage(`inserting AutoAPR Banner via ddc api at ${css_selector} for ${templateKey}`);
                    DDC_API.append(elem, insertion_element);
                    install_listeners_func();
                  }
                } catch (err) {
                  console.log("error calling DDC.append", err);
                }
              });
            } else {
              debugMessage(`installAutoAprDdcLandingPage: pageName ${ev.payload.pageName} did not match expected page names.`);
            }
            resolve();
          });
        } catch (err) {
          reject(err);
        }
      });
    };
    function getAutoAprLocationConfig() {
      if (autoapr_config?.locations) {
        return autoapr_config?.locations[tpUtil.getPluginLocation()];
      }
      return null;
    }
    function installAutoAprBannerOrLandingPage(plugin_config) {
      const templateKeys = Object.keys(AA_PRODUCT_MAP).filter((key) => key.match(/banner/) || key.match(/landing_page/));
      for (const templateKey of templateKeys) {
        debugMessage("install AutoAPR: ", templateKey);
        const uniqueClass = templateKey;
        const templateConfig = plugin_config.templates[templateKey];
        if (shouldInstallBannerOrCta(templateConfig)) {
          if (templateConfig?.widget_css) {
            addCssToHead(templateKey, templateConfig.widget_css);
          }

          const selector = is_mobile ? templateConfig.mobile_selector || templateConfig.selector : templateConfig.selector;
          const existingElements = jQuery(selector).parent().find(`.${uniqueClass}`);
          if (existingElements.length >= 1) {
            // short circuit if element already exists
            break;
          }
          let widget_insert_method = "append";

          if (is_mobile && typeof templateConfig.mobile_insert_method !== "undefined") widget_insert_method = templateConfig.mobile_insert_method;
          else if (typeof templateConfig.insert_method !== "undefined") widget_insert_method = templateConfig.insert_method;

          const el = build_wrapper(uniqueClass, templateConfig.widget_html);
          const form = jQuery("form", el);
          const config = {
            is_autoapr: true,
            ga_category_name: undefined,
            url_constructor: undefined,
            analytics: {
              cta: templateKey,
            },
          };
          // track which banners are enabled
          addEventContext("aa_cta_click", `${templateKey}`, true);

          const addFormListener = form?.length > 0;
          const addListeners = () => {
            if (templateKey === "combined_banner") {
              addPaymentExplorerEventListener(templateKey, { ...config });
              addApproveEventListener(templateKey, { ...config });
            } else if (addFormListener) {
              addPaymentExplorerEventListener(templateKey, config);
            } else {
              addApproveEventListener(templateKey, config);
            }
          };
          if (DDC_API && plugin_config.use_ddc_api) {
            if (templateKey.match(/banner/)) {
              installAutoAprPaymentBannerDDC(selector, el, templateKey, addListeners);
            } else if (templateKey.match(/landing/) && tpUtil.isDdcLandingPage()) {
              installAutoAprDdcLandingPage(selector, el, templateKey, addListeners);
            }
          } else {
            const banner_location = jQuery(selector);
            debugMessage("Using plugin: " + tpUtil.getPluginLocation() + " and insert method: " + widget_insert_method + " on element: ", banner_location[0]);

            if (banner_location.length === 0 && !(DDC_API && plugin_config.use_ddc_api)) {
              console.error(`Unable to find selector ${selector} for inserting ${templateKey}`);
            }

            const widget_el = el;
            let widget_html = widget_el.outerHTML;
            if (widget_html.includes("<iframe")) {
              widget_html = tpUtil.add_utm_params_to_iframe(widget_html, true);
            }

            apply_insert_method(banner_location, widget_insert_method, widget_html);
            addListeners();
          }

          postAnalytics("aa_exp_view_banner");
        }
      }
    }

    function addPaymentExplorerEventListener(templateKey: string, config: any) {
      const mountedForm = document.querySelectorAll(".aa-exp-card form");
      if (mountedForm?.length > 0) {
        for (const bannerForm of Array.from(mountedForm)) {
          bannerForm.addEventListener("submit", (ev) => {
            ev.preventDefault();
            const formInput = jQuery("input", mountedForm)[0];

            let url = `${templateKey === "combined_banner" ? AA_PRODUCT_MAP[templateKey].url("explorer") : AA_PRODUCT_MAP[templateKey].url}${autoapr_config.dealer_id}/`;
            if (formInput && formInput.value.length > 0) {
              // add budget to URL
              url += `${formInput.value}`;
            }
            config.ga_category_name = AA_PRODUCT_MAP[templateKey].product_name;
            config.url_constructor = () => url;
            fire_autoapr_start_events(config);
            launchOverlay(config, launchColorboxFullProcessPopup);
          });
        }
      }
    }

    function addApproveEventListener(templateKey: string, config: any) {
      config.ga_category_name = AA_PRODUCT_MAP[templateKey].product_name;
      config.url_constructor = () => `${templateKey === "combined_banner" ? AA_PRODUCT_MAP[templateKey].url("approve") : AA_PRODUCT_MAP[templateKey].url}${autoapr_config.dealer_id}/`;
      const button = jQuery(".aa-apv-btn");
      if (button?.length > 0) {
        button[0].onclick = (evt) => {
          evt.preventDefault();
          fire_autoapr_start_events(config);
          logEvent("aa_banner_click", { banner: templateKey });
          launchOverlay(config, launchColorboxFullProcessPopup);
        };
      }
    }

    function shouldInstallBannerOrCta(templateConfig) {
      const locationPath = tpUtil.getPath();
      const pluginLocationConfig = plugin_location_configs[tpUtil.getPluginLocation()];
      if (!templateConfig) {
        return false;
      }
      if ((is_mobile && templateConfig?.disable_mobile) || (!is_mobile && templateConfig?.disable_desktop)) {
        return false;
      }
      if (
        (templateConfig?.disable_on_new && pluginLocationConfig?.new_url_regex && locationPath.match(pluginLocationConfig?.new_url_regex)) ||
        (templateConfig?.disable_on_used && pluginLocationConfig?.used_url_regex && locationPath.match(pluginLocationConfig?.used_url_regex))
      ) {
        return false;
      }
      return true;
    }

    async function installDDCCallToAction(plugin) {
      const launchDDCOverlay = (vin) => () => {
        plugin.vin = vin;
        launchOverlay(plugin, ddcCtaButtonClicked);
      };
      DDC_API.subscribe("vehicle-data-updated-v1", (ev) => {
        let is_lease = plugin.cta_unique_class === "lease_cta_button";
        if (plugin.use_ddc_api_append) {
          ev.payload.vehicleData.forEach((vehicle) => {
            const insert_location = "vehicle-ctas";
            DDC_API.insertOnce(insert_location, (elem, meta) => {
              try {
                let isSnapCta = plugin.cta_unique_class === "snap-cta";
                let wrapper = build_wrapper(plugin.cta_unique_class, isSnapCta ? getSnapCTATemplate(plugin, jQuery(TP_CTA_SELECTOR).get(0)) : plugin.cta_html_code);
                if (plugin.template_key === "combined_cta") {
                  wrapper = filterAutoAprCombinedCtaActions(plugin, wrapper);
                }

                if (!is_lease || meta.inventoryType === "new") {
                  const metaVin = meta.vin;
                  wrapper.setAttribute("data-vin", metaVin);
                  debugMessage("inserting CTA via ddc api: " + insert_location);
                  DDC_API.append(elem, wrapper);

                  if (isSnapCta) {
                    installButtonListeners(jQuery(wrapper), launchDDCOverlay(metaVin));
                  } else {
                    let priceParam = "";
                    if (plugin.ddc_price_override && plugin.ddc_price_override != "None") {
                      const price = meta[plugin.ddc_price_override];
                      if (price) {
                        priceParam = `/${price}`;
                      }
                    }
                    let condYearParam = "";
                    if (plugin.ddc_year_condition_override && plugin.ddc_year_condition_override === true) {
                      const condition = meta.inventoryType == "new" ? "n" : "u";
                      const year = meta.year;
                      condYearParam = `?cd=${condition}&yr=${year}`;
                    }
                    if (plugin.template_key == "combined_cta") {
                      plugin.intermediate_url_constructor = (cta_key) => `${AA_PRODUCT_MAP[cta_key].url}${autoapr_config.dealer_id}/${metaVin}${priceParam}${condYearParam}`;
                      installAutoAprCombinedCtaListeners(Object.assign({}, plugin));
                    } else {
                      const isAutoApr = AA_PRODUCT_MAP.hasOwnProperty(plugin.template_key);
                      plugin.url_constructor = () => {
                        if (isAutoApr) {
                          return `${AA_PRODUCT_MAP[plugin.template_key].url}${autoapr_config.dealer_id}/${metaVin}${priceParam}${condYearParam}`;
                        }
                        const plugin_location = tpUtil.getPluginLocation();
                        return `${window.location.protocol}//${plugin_server}/contact/form?type=${CTAS_MAP[plugin.template_key].type}&did=${plugin_id}&loc=${plugin_location}&vin=${metaVin}`;
                      };
                      if (isMobile()) {
                        installButtonListeners(jQuery(wrapper), mobileButtonListenerFunc(Object.assign({}, plugin)));
                      } else {
                        installButtonListeners(jQuery(wrapper), desktopButtonListenerFunc(Object.assign({}, plugin)));
                      }
                    }
                  }
                }
              } catch (err) {
                console.log("error calling DDC.append", err);
              }
            });
          });
        } else {
          DDC_API.insertCallToActionOnce("button", plugin.cta_intent || "value-a-trade", (meta) => {
            if (!is_lease || meta.inventoryType === "new") {
              const getStyle = (style_name, value) => {
                return (tpUtil.exists(value) && `${style_name}: ${value};`) || "";
              };

              let style = getStyle("border", plugin.cta_button_border_style);
              style += getStyle("border-radius", plugin.cta_button_border_radius);
              style += getStyle("color", plugin.cta_button_text_color);
              style += getStyle("background", plugin.cta_button_color);

              let pluginResponse: DdcCtaPluginConfig = {
                type: plugin.cta_button_type || "default",
                target: "_blank",
                onclick: launchDDCOverlay(meta.vin),
                text: {
                  en_US: plugin.cta_button_text || "Value Your Trade",
                  en_CA: plugin.cta_button_text || "Value Your Trade",
                  fr_CA: plugin.cta_button_text || "Obtenez la valeur de votre échange",
                  es_MX: plugin.cta_button_text || "Conozca el Valor de su Auto",
                },
                style,
                classes: plugin.cta_button_class,
              };
              const autoaprImageInfo = plugin.cta_html_code ? jQuery(".aa-svg", plugin.cta_html_code) : [];
              if (autoaprImageInfo?.length > 0) {
                let classes = jQuery(plugin.cta_html_code).prop("className");
                classes += jQuery(plugin.cta_html_code).children().prop("className");
                pluginResponse.classes = classes;
                pluginResponse.imgClasses = autoaprImageInfo[0].getAttribute("class");
                pluginResponse.imgSrc = autoaprImageInfo[0].getAttribute("src");
                pluginResponse.imgAlt = autoaprImageInfo[0].getAttribute("alt");
              }
              return pluginResponse;
            }
          });
        }
      });
    }

    async function installAutoAprCombinedCta(plugin, node) {
      plugin.cta_html_code = build_wrapper(plugin.cta_unique_class, plugin.cta_html_code);
      const cta_element = jQuery(node).find(plugin.cta_insert_selector).first();
      if (cta_element.length > 0) {
        plugin.button_selector = `.${plugin.cta_unique_class}`;
        cta_element.parent().find(plugin.button_selector).remove();
        plugin.cta_html_code = filterAutoAprCombinedCtaActions(plugin, plugin.cta_html_code).outerHTML;
        apply_insert_method(cta_element, plugin.cta_insert_method, plugin.cta_html_code);
        plugin.cta_html_code = installAutoAprCombinedCtaListeners(plugin);
      } else {
        console.error("TradePending: TradePending CTA selector not found: " + plugin.cta_insert_selector);
      }
    }

    function filterAutoAprCombinedCtaActions(plugin, element) {
      const node = jQuery(element);
      for (const cta of AA_PRODUCT_MAP.combined_cta.ctas) {
        const condition_check = AA_PRODUCT_MAP[cta].use_condition_check;
        if (!plugin.ctas_enabled.has(cta) || (condition_check && !condition_check(plugin.vehicle_condition?.condition))) {
          node.find(`.aa-combined-cta-${cta}`).remove();
        }
      }
      return node[0] as HTMLElement;
    }

    function installAutoAprCombinedCtaListeners(plugin) {
      for (const cta of AA_PRODUCT_MAP.combined_cta.ctas) {
        if (plugin.ctas_enabled.has(cta)) {
          plugin.button_selector = `.aa-combined-cta-${cta}`;
          if (plugin.intermediate_url_constructor) {
            plugin.url_constructor = () => plugin.intermediate_url_constructor(cta);
          } else {
            plugin.url_constructor = (vin) => `${AA_PRODUCT_MAP[cta].url}${autoapr_config.dealer_id}/${vin}`;
          }
          addEventContext("aa_cta_click", `${plugin.analytics.cta}_${cta}_enabled`, true);
          installDesktopButtonListeners({ ...plugin, analytics: { cta: `${plugin.analytics.cta}_${cta}` } });
          installMobileButtonListeners({ ...plugin, analytics: { cta: `${plugin.analytics.cta}_${cta}` } });
        }
      }
    }

    async function installCallToAction(plugin, selector, useDdcApi, installCtaFn: (plugin, node: HTMLElement) => void, retries = 0) {
      if (useDdcApi) {
        return await installDDCCallToAction(plugin);
      } else {
        retries = 0;
        let containerNodes = jQuery(selector);
        while (!(containerNodes?.length > 0) && retries < 4) {
          await tpUtil.sleep(retries++ * 500);
          containerNodes = jQuery(selector);
        }
        if (containerNodes?.length === 0) {
          console.error("TradePending: Could not find container node to insert call to action", selector);
        }
        if (!plugin.use_ddc_api && (plugin.use_cta || containerNodes)) {
          const use_stock_numbers = plugin.use_stock_numbers;
          const use_condition_check = plugin.use_condition_check;

          if (use_condition_check || use_stock_numbers) {
            let vehicles_identification_list = [];
            let cta_selector = use_stock_numbers ? plugin.cta_stock_number_selector : plugin.cta_vin_selector;
            containerNodes.each((index, containerNode) => {
              if (cta_selector) {
                const vehicle_identification = getDataFromElement(containerNode, cta_selector, use_stock_numbers ? ExtractedItem.STOCK_NUMBER : ExtractedItem.VIN);
                if (vehicle_identification) {
                  vehicles_identification_list.push(vehicle_identification);
                } else {
                  console.error(
                    `TradePending: Error finding ${use_stock_numbers ? "Stock Number" : "VIN"} from ${cta_selector} on containerNode, check ${use_stock_numbers ? "Stock Number" : "VIN"} selector under node selector. Node is :`,
                    containerNode
                  );
                }
              }
            });
            if (vehicles_identification_list.length > 0) {
              const url = `//${plugin_server}/vin/${use_stock_numbers ? "vinsfromstocknumbers" : "vehiclescheck"}`;
              if (use_stock_numbers) {
                await vinsFromStockNumbersApiCache.initCacheForService(vehicles_identification_list, url);
              } else {
                await vehiclesConditionApiCache.initCacheForService(vehicles_identification_list, url);
              }
            }
          }

          containerNodes.each((_index, containerNode) => {
            let node_plugin = Object.assign({}, plugin);
            if (use_stock_numbers && node_plugin.cta_stock_number_selector) {
              let stock_number = getDataFromElement(containerNode, node_plugin.cta_stock_number_selector, ExtractedItem.STOCK_NUMBER);
              const vinFromCache = vinsFromStockNumbersApiCache.cache[stock_number]?.result;
              node_plugin.vin = vinFromCache?.vin;
              if (use_condition_check) {
                node_plugin.vehicle_condition = vinFromCache;
              }
            } else if (node_plugin.cta_vin_selector) {
              node_plugin.vin = getDataFromElement(containerNode, node_plugin.cta_vin_selector, ExtractedItem.VIN);
            }
            const conditionFromCache = vehiclesConditionApiCache.cache[node_plugin.vin]?.result;
            let vehicleCondition = conditionFromCache?.condition || node_plugin.vehicle_condition?.condition;
            let is_new_price_selector_enabled = (!vehicleCondition || vehicleCondition === VehicleCondition.NEW) && !node_plugin.price_selector_disabled_new;
            let is_used_price_selector_enabled = vehicleCondition !== VehicleCondition.NEW && !node_plugin.price_selector_disabled_used;
            if (node_plugin.cta_price_selector && (is_new_price_selector_enabled || is_used_price_selector_enabled)) {
              node_plugin.price = getDataFromElement(containerNode, node_plugin.cta_price_selector, ExtractedItem.PRICE);
            }
            if (!use_condition_check || (use_condition_check(vehicleCondition) && node_plugin.vin)) {
              if (!use_stock_numbers && use_condition_check) {
                node_plugin.vehicle_condition = conditionFromCache;
              }
              installCtaFn(node_plugin, containerNode);
            }
          });
        }
      }
    }

    function installCallToActionDefault(plugin, containerNode, retries = 0) {
      const cta_insert_selector = plugin.cta_insert_selector || plugin.cta_css_selector;
      const cta_insert_method = plugin.cta_insert_method;
      const cta_html_code = plugin.cta_html_code;
      if (!cta_insert_selector) {
        console.log("CTA: no valid css selector provided");
        return;
      }
      let cta_html: string | HTMLElement;
      if (cta_html_code) {
        cta_html = cta_html_code;
      } else if (containerNode) {
        cta_html = getSnapCTATemplate(plugin, containerNode);
      }

      let cta_element = jQuery(containerNode).find(cta_insert_selector).first();
      if (cta_element.length === 0) {
        cta_element = jQuery(cta_insert_selector);
      }
      debugMessage(`Using plugin: ${tpUtil.getPluginLocation()} and insert method: ${cta_insert_method} on element: ${cta_element[0]}`);

      if (jQuery(`.${plugin.cta_unique_class}`, cta_html).length === 0) {
        cta_html = build_wrapper(plugin.cta_unique_class, cta_html);
      }
      if (cta_element.length > 0) {
        if (plugin.cta_unique_class) {
          const selector = `.${plugin.cta_unique_class}`;
          // find the parent of cta_element because we sometimes insert cta_html as a sibling.
          const toRemove = cta_element.parent().find(selector);
          toRemove.remove();
          plugin.button_selector = selector;
        }
        apply_insert_method(cta_element, cta_insert_method, cta_html);
        if (retries >= 0) {
          installDesktopButtonListeners(plugin);
          installMobileButtonListeners(plugin);
        }
      } else {
        if (retries < 4) {
          debugMessage("Retrying CTA install, retry", retries + 1);
          setTimeout(() => {
            installCallToActionDefault(plugin, containerNode, retries + 1);
          }, retries * 500);
        } else {
          console.error("TradePending: TradePending CTA selector not found: " + cta_insert_selector);
        }
      }
    }

    function getSnapCTATemplate(plugin, containerNode: HTMLElement) {
      const clone = jQuery("<div />").append(jQuery(containerNode).clone());
      const button = clone.find(".tradepending-cta button");
      const snapCtaButton = clone.find(".tradepending-cta button.tp-btn");
      if (button?.length === 0) {
        if (process.env.DEBUG_ENABLED) {
          console.error("TradePending: Trying to install CTA but .tradepending-cta button is missing. Is this an incorrect config or are we missing cta_html_code?");
        }
      }
      if (plugin.cta_unique_class === "snap-cta" && snapCtaButton?.length > 0) {
        snapCtaButton.addClass("snap-cta");
        addMobileOrDesktopClasses(snapCtaButton);
      }
      if (plugin.cta_button_class) {
        snapCtaButton.addClass(plugin.cta_button_class);
      }
      button.data("initiator", "cta_button");
      return clone[0].outerHTML;
    }

    const installAutoAprFloatingButton = (location_config) => {
      const template_keys = Object.keys(AA_PRODUCT_MAP).filter((key) => key.match(/floating_button/));
      const elementId = "aa-floating-button";
      const id = `#${elementId}`;
      for (const template_key of template_keys) {
        const button_template = location_config.templates[template_key];
        if (jQuery(id).length > 0) {
          jQuery(id).remove();
        }
        if (!button_template || (is_mobile && button_template.disable_mobile) || (!is_mobile && button_template.disable_desktop)) {
          return;
        }
        addEventContext("aa_cta_click", template_key, true);
        const el = jQuery(button_template.widget_html);
        el.prop("id", elementId);
        el.click(() => {
          const config = {
            is_autoapr: true,
            ga_category_name: AA_PRODUCT_MAP[template_key].product_name,
            url_constructor: () => `${AA_PRODUCT_MAP[template_key].url}${autoapr_config.dealer_id}/`,
            analytics: { cta: template_key },
          };
          fire_autoapr_start_events(config);
          launchOverlay(config, launchColorboxFullProcessPopup);
        });
        document.body.append(el[0]);
        addCssToHead(template_key, button_template.widget_css);
        postAnalytics("aa_exp_view_float");
      }
    };

    function installFloatingButton(plugin_config) {
      if (jQuery("#tradepending-floating").length) {
        const options = {};
        const button = jQuery("#tradepending-floating > button");
        options["data-scroll-hide"] = button.attr("data-scroll-hide");
        options["data-scroll-hide-fade"] = button.attr("data-scroll-hide-fade");
        options["data-pin-to"] = button.attr("data-pin-to");
        options["data-pin-offset"] = button.attr("data-pin-offset");
        options["data-pin-offset-mobile"] = button.attr("data-pin-offset-mobile");
        options["data-avoid-transforms"] = button.attr("data-avoid-transforms");
        if (options["data-avoid-transforms"] == "true") {
          const tmp = jQuery("#tradepending-floating").detach();
          jQuery("body").append(tmp);
        }
        button.data("initiator", "floating_button");
        addMobileOrDesktopClasses(button);

        if (options["data-pin-to"] && jQuery(options["data-pin-to"]).length) {
          const positionButton = function () {
            const right = jQuery(options["data-pin-to"]).get(0).getBoundingClientRect().right;
            let offset = options["data-pin-offset"];
            if (is_mobile && options["data-pin-offset-mobile"]) {
              offset = options["data-pin-offset-mobile"];
            }
            if (!offset) {
              offset = 0;
            }
            const viewportWidth = jQuery(window).width();
            jQuery("#tradepending-floating")
              .css("right", viewportWidth)
              .css("right", "-=" + right)
              .css("right", "+=" + offset);
          };
          positionButton();
          jQuery(window).on("resize", positionButton);

          if (options["data-scroll-hide"] == "true" && jQuery("#tradepending-container").length > 0) {
            const setButtonVisibility = function (fadeDuration) {
              if (jQuery("#tradepending-container").length > 0 && jQuery("#tradepending-floating").length > 0) {
                const top = jQuery("#tradepending-container").offset().top;
                const bottom = top + jQuery("#tradepending-container").outerHeight();
                const windowTop = jQuery(window).scrollTop();
                const windowBottom = windowTop + jQuery(window).height();
                if (bottom > windowTop && top < windowBottom) {
                  jQuery("#tradepending-floating").fadeOut(fadeDuration);
                } else {
                  jQuery("#tradepending-floating").fadeIn(fadeDuration);
                }
              }
            };
            let fade = 300;
            if (options["data-scroll-hide-fade"]) {
              try {
                fade = parseInt(options["data-scroll-hide-fade"]);
              } catch (ex) {
                console.error("TradePending: Could not parse scroll hide fade duration: " + options["data-scroll-hide-fade"]);
              }
            }
            setButtonVisibility(0);

            const handler = function () {
              setButtonVisibility(fade);
            };
            window.addEventListener("resize", handler, { passive: true });
            window.addEventListener("scroll", handler, { passive: true });
          } else {
            jQuery("#tradepending-floating").show();
          }
        } else {
          console.error("TradePending: Could not find element used to set floating button position: " + options["floating-button-pin-selector"]);
        }
      } else {
        debugMessage("No #tradepending-floating found. Nothing to do.");
      }
    }

    function installButtonListeners(buttons, fn: Function) {
      buttons.each(function () {
        const btn = jQuery(this);
        if (!btn.data("tpListenerInstalled")) {
          btn.data("tpListenerInstalled", true);
          btn.click(fn);
        }
      });
    }

    const mobileButtonListenerFunc = (plugin) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      let initiator;
      if (event.target && jQuery(event.target).data("initiator")) {
        initiator = jQuery(event.target).data("initiator");
        postAnalytics(initiator);
      } else {
        postAnalytics("widget_button");
        initiator = "button";
      }
      if (plugin.is_autoapr) {
        fire_autoapr_start_events(plugin);
      } else {
        if (initiator == "button") {
          fire_asc_cta_event({ product: "Trade", actionResult: "open", elementText: plugin.cta_button_text });
        }
        fire_tradein_start_events(initiator);
      }
      const iframe = buildVehicleSelectFrame({
        is_mobile: true,
        is_mobile_overlay: true,
        show_close_button: true,
        plugin,
        classes: "overlay-iframe",
      });
      launchMobileOverlay(iframe);
    };
    function installMobileButtonListeners(plugin) {
      // entry point for mobile
      const button_selector = plugin.button_selector || ".tp-btn-vehicle-select";
      installButtonListeners(jQuery(button_selector), mobileButtonListenerFunc(plugin));
    }

    const installMutationObserver = (plugin_config) => {
      if (plugin_config.reinstall_location_change) {
        const debouncedInstall = tpUtil.simpleDebounce(install, 500);
        window.addEventListener("tradepending-route-change", () => {
          debugMessage("tradepending-route-change fired, re-installing");
          debouncedInstall();
        });

        window.addEventListener("popstate", (event) => {
          debugMessage("back button / popstate, re-installing");
          debouncedInstall();
        });
        let oldHref = document.location.href;
        let bodyDOM = document.querySelector("body");
        const config = {
          childList: true,
          subtree: true,
        };
        const observer = new MutationObserver(function (mutations) {
          if (oldHref != document.location.href) {
            observer.disconnect();
            oldHref = document.location.href;
            jQuery("#tradepending-container").remove();

            window.requestAnimationFrame(function () {
              debouncedInstall();
              let tmp = document.querySelector("body");

              if (tmp != bodyDOM) {
                bodyDOM = tmp;
                observer.observe(bodyDOM, config);
              }
            });
          }
        });
        observer.observe(bodyDOM, config);
      }
    };

    const desktopButtonListenerFunc = (plugin) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      let initiator;
      if (event.target && jQuery(event.target).data("initiator")) {
        initiator = jQuery(event.target).data("initiator");
        postAnalytics(initiator);
      } else {
        postAnalytics("cta_button");
        initiator = "button";
      }
      if (plugin.is_autoapr) {
        fire_autoapr_start_events(plugin);
      } else {
        if (initiator == "button") {
          fire_asc_cta_event({ product: "Trade", actionResult: "open", elementText: plugin.cta_button_text, vehicle: plugin.vehicle_condition });
        }
        fire_tradein_start_events(initiator);
      }
      launchOverlay(plugin, launchColorboxFullProcessPopup);
    };

    function installDesktopButtonListeners(plugin) {
      // entry point for showing the full vehicle selection process, contact form, additional attributes, report
      const button_selector = (plugin.button_selector !== ".snap-cta" && plugin.button_selector) || ".tp-btn-full-frame";
      installButtonListeners(jQuery(button_selector), desktopButtonListenerFunc(plugin));
    }

    function addMobileOrDesktopClasses(button: any) {
      if (isMobile()) {
        button.removeClass("tp-btn-full-frame").addClass("tp-btn-vehicle-select");
      } else {
        button.removeClass("tp-btn-vehicle-select").addClass("tp-btn-full-frame");
      }
    }

    function launchOverlay(plugin, desktopLaunchMethod) {
      if (is_mobile) {
        const iframe = buildVehicleSelectFrame({
          is_mobile: true,
          is_mobile_overlay: true,
          show_close_button: true,
          plugin,
          classes: "overlay-iframe",
        });
        launchMobileOverlay(iframe);
      } else {
        desktopLaunchMethod(plugin);
      }
    }

    function installTypeahead(plugin_config) {
      let timeout;
      jQuery("#tradepending-vehicle-typeahead, .tradepending-vehicle-typeahead")
        .typeaheadTP(
          {
            highlight: true,
            hint: false,
          },
          {
            name: "vehicle-search",
            displayKey: "ymmt",
            source: function (query, callback) {
              if (timeout) {
                clearTimeout(timeout);
              }
              const timeoutFunc = async () => {
                let csrf_token = null;
                if (!typeahead_activated) {
                  typeahead_activated = true;
                  postAnalytics("typeahead");
                  fire_tradein_start_events("typeahead");
                  if (delete_csrf_token) {
                    csrf_token = jQuery.ajaxSettings.headers["X-CSRF-Token"];
                    debugMessage("Temporarily storing CSRF token: ", csrf_token);
                  }
                }
                const current_year = new Date().getFullYear();
                const q = {
                  size: 10,
                  query: {
                    bool: {
                      must_not: [{ term: { "year.raw": (current_year + 1).toString() } }, { term: { "year.raw": (current_year + 2).toString() } }, { term: { "year.raw": (current_year + 3).toString() } }],
                      must: { match: { all_fields: { fuzziness: "AUTO:5,8", query: query, operator: "and" } } },
                    },
                  },
                } as any;
                if (country !== "CA") {
                  q.query.bool.must_not.push({ term: { "country.raw": "CA" } });
                }

                jQuery.support.cors = true;
                if (delete_csrf_token) {
                  delete jQuery.ajaxSettings.headers["X-CSRF-Token"];
                  debugMessage("Deleted CSRF token");
                }
                try {
                  const response = await fetch(search_url, {
                    method: "POST",
                    headers: {
                      "Content-Type": "text/plain",
                      Authorization: "Basic " + btoa("turncar-two-snap-access:bximuxmt0fmectt6fucw89f7fhd3nshe"),
                    },
                    body: JSON.stringify(q),
                  });
                  if (!response?.ok) {
                    throw new Error("Plugin error: " + response);
                  }
                  const data = await response.json();
                  const vehicles: any = [];
                  data.hits.hits.forEach((vehicle) => {
                    const v = vehicle._source;
                    const ymmt = v.ymm + " " + v.trim;
                    const car = { id: vehicle._id, ymmt: ymmt, year: v.year, make: v.make, model: v.model, trim: v.trim };
                    vehicles.push(car);
                  });
                  callback(vehicles);
                } catch (ex) {
                  console.error(ex);
                }
                if (csrf_token != null && delete_csrf_token) {
                  jQuery.ajaxSettings.headers["X-CSRF-Token"] = csrf_token;
                  debugMessage("Adding back CSRF token", csrf_token);
                }
              };
              timeout = setTimeout(timeoutFunc, 100);
            },
          }
        )
        .bind("typeahead:selected", function (evt, vehicle, name) {
          jQuery("#tradepending-vehicle-typeahead, .tradepending-vehicle-typeahead").typeaheadTP("val", "");
          fire_vehicle_selected_events(vehicle);
          launchContactForm(vehicle);
        });
    }

    function installSuperlatives() {
      if (typeof superlative_config == "object" && shouldShowSuperlatives(tpUtil.getPluginLocation())) {
        debugMessage("superlatives enabled with data:", superlative_config);
        superlative_active = true;
        const superlative_location_config = setup_superlatives_location_config(superlative_config);
        if (DDC_API && superlative_location_config.use_ddc_api) {
          setup_superlatives_ddc();
        } else {
          setup_superlatives();
        }
      }
    }

    function generateId(size: number) {
      let id = "";
      const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (let i = 0; i < size; i++) {
        id += chars[Math.floor(Math.random() * chars.length)];
      }
      return id;
    }

    function maybeLaunchApprove() {
      const queryParams = parse_query_params();
      if (queryParams?.["tp_launch_approve"]) {
        debugMessage("SNAP.maybeLaunchApprove() launching approve");
        const url = `${AA_PRODUCT_MAP["approve_cta"].url}${autoapr_config.dealer_id}/`;
        const internal_usage = !!queryParams["tp_internal_usage"];
        const config = {
          url_constructor: () => url,
          additional_params: { internal_usage },
        };
        launchOverlay(config, launchColorboxFullProcessPopup);
      }
    }

    const maybeLaunchAutobio = () => {
      const queryParams = parse_query_params();
      if (queryParams) {
        debugMessage("SNAP.maybeLaunchAutobio() maybe launching autobio or package item");
        const vin = queryParams["tp_autobio_vin_code"] || queryParams["tp_autobio_vin"];
        const shortId = queryParams["tp_autobio_shortid"];

        const packageId = queryParams["tp_package"];
        const leadId = queryParams["tp_lead"];
        let url = "";
        const showPackageItem = packageId && shortId && leadId;
        const showAutobioReport = vin && shortId;
        if (showPackageItem) {
          url = `//${plugin_server}/apps/autobio/api/package/item-details/${shortId}/${packageId}/${leadId}`;
          postAnalytics("autobio_package_auto_launch", { vin, packageId, leadId });
        } else if (showAutobioReport) {
          const dealerUserId = queryParams["tp_dealer_user_id"];
          const shareType = queryParams["st"];
          const userQueryString = dealerUserId ? `&userId=${dealerUserId}` : ``;
          url = `//${plugin_server}/apps/autobio/bio/${shortId}/${vin}?st=${shareType}${userQueryString}`;
          postAnalytics("autobio_report_auto_launch", { vin, shortId });
        }

        const config = {
          url_constructor: () => url,
        };
        if (url) {
          launchOverlay(config, launchColorboxFullProcessPopup);
        }
      }
    };

    async function install() {
      let plugin_location = null;
      debugMessage("SNAP.install()");
      linkPromise = setupLink();
      const r = document.cookie.match(/tpa_session=(.*?)(?:;|$)/);
      if (r) {
        tp_analytics_session_id = r[1];
        debugMessage("Using existing tp_analytics_session_id: " + tp_analytics_session_id);
      } else {
        tp_analytics_session_id_is_new = true;
        tp_analytics_session_id = Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
        debugMessage("Created new tp_analytics_session_id: " + tp_analytics_session_id);
      }
      delete_csrf_token = plugin_config.delete_csrf && jQuery.ajaxSettings && jQuery.ajaxSettings.headers;
      debugMessage("SNAP debugging enabled");
      debugMessage("plugin_config: ", plugin_config);
      debugMessage("plugin_data: ", plugin_data);
      if (plugin_config.delete_csrf) {
        debugMessage("delete_csrf_token feature enabled: ", delete_csrf_token);
        debugMessage("jquery.ajaxSettings: ", jQuery.ajaxSettings);
      }

      const init_cookie_match = document.cookie.match(/tp_initial_url=(.*?)(?:;|$)/);
      if (!init_cookie_match) {
        const maxage = 3 * 24 * 60 * 60;
        document.cookie = "tp_initial_url=" + window.location.href + "; max-age=" + maxage + ";";
        if (typeof document.referrer !== "undefined") {
          document.cookie = "tp_referrer_url=" + document.referrer + "; max-age=" + maxage + ";";
        }
      }

      const tpa_vdp_match = document.cookie.match(/tpa_vdp=(.*?)(?:;|$)/);
      if (!tpa_vdp_match || !tpa_vdp_match[1]) {
        document.cookie = "tpa_vdp=" + generateId(17) + "; max-age=" + 90 * 24 * 60 * 60 + ";";
      }

      const path = tpUtil.getPath();
      debugMessage("Using current path of: " + path);
      const result = findPluginLocation(path);
      plugin_location = result.location;

      await initFirebase(process.env.DEALER_ID, plugin_location);

      DDC_API = await waitForDDCAPI(plugin_location);

      if (typeof window.sd === "function" && SHIFT_OEMS.includes(plugin_config.program_oem)) {
        debugMessage("Asking for shift sessionId");
        window.sd("getSessionId", (sessionId) => {
          debugMessage("Setting shift session id in cookie: ", sessionId);
          document.cookie = "tp_shift_session_id=" + sessionId;
        });
      }
      debugMessage("Using plugin location: " + plugin_location);
      jQuery("head").append("<style>" + global_styles + "</style>");

      // Call 'off' first in case install() has been called before
      jQuery(window).off("message onmessage", receiveMessage);
      jQuery(window).on("message onmessage", receiveMessage);

      addCssLinkToHead("tradepending-fa", "https://cdn.tradepending.com/fontawesome_kits/kit-477fb8cd51-web/css/all.min.css");
      installSuperlatives();
      if (plugin_config.snap_pending) {
        postAnalytics("notify");
      }

      const plugin = plugin_data[plugin_location];
      if (plugin_location == null || typeof plugin === "undefined") {
        debugMessage("SNAP Plugin not configured for path: " + path);
        snap_widget_installed = true;
      } else if (isBlockedStagingSite(plugin_config.snap_status)) {
        debugMessage("SNAP not displaying because it's a staging site that doesn't match the configured staging url: " + plugin_config.staging_url);
        snap_widget_installed = true;
      } else if (plugin_data[plugin_location].status == "disabled") {
        debugMessage("SNAP not displaying because disable flag is set for location: " + plugin_location);
        snap_widget_installed = true;
      } else if (!shouldInstallBannerOrCta(plugin)) {
        debugMessage("SNAP not displaying because mobile/desktop or new/used disable flag is set for location: " + plugin_location);
        snap_widget_installed = true;
      } else {
        if (typeof plugin.locale !== "undefined") {
          plugin_page_locale = plugin.locale;
        }

        if (typeof plugin?.css !== "undefined") {
          addCssToHead(`tp_snap_custom_css`, plugin.css);
        }
        if (typeof plugin?.css_compiled !== "undefined") {
          addCssToHead(`tp_snap_css_template`, plugin.css_compiled);
        }
        let widget_html = "";
        if (typeof plugin?.html_template !== "undefined") {
          widget_html += plugin.html_template;
        } else if (typeof plugin?.html_compiled !== "undefined") {
          widget_html += plugin.html_compiled;
        } else if (typeof plugin?.html_code !== "undefined") {
          widget_html = plugin.html_code;
        }
        widget_html = "<div style='clear: both;'></div>" + widget_html + "<div style='clear: both;'></div>";

        if (tpUtil.isIE() && !is_mobile) widget_html = widget_html.replace(new RegExp(/(@media\s*\(max-width:\s*767px\)\s*\{[\s\S]*?}\s*})/gim), "");

        if (plugin_config.snap_disabled) {
          debugMessage("SNAP not displaying because disable flag is set");
          snap_widget_installed = true;
        } else {
          await insert_widget(plugin, widget_html);
        }
        if (snap_widget_installed) {
          placeholder();
          enableDisplay();
          if (!vrp_ready_listener_installed && plugin_location == "srp" && plugin?.allow_multiple) {
            installReloadListeners(plugin_config);
          }
          installTypeahead(plugin_config);
          installFloatingButton(plugin_config);

          installMobileButtonListeners(plugin);
          installMutationObserver(plugin_config);
          setupAccessibility();
          preventInputHighjacks();
        }
        installDesktopButtonListeners(plugin);
      }
      await installAutoApr();
      debouncedInstallCtas = tpUtil.simpleDebounce(() => installCtas(), 500);
      await installCtas();
      maybeLaunchApprove();
      maybeLaunchAutobio();

      if (typeof handleSnapAfterAnalytics === "function") {
        handleSnapAfterAnalytics({ type: "vdp" });
      }

      tpUtil.setup_refresh_events(plugin_location_configs, () => {
        const product_plugin_location_config = plugin_location_configs[tpUtil.getPluginLocation()] || {};
        setTimeout(() => {
          if (debouncedInstallCtas) {
            debouncedInstallCtas();
          }
          if (debouncedInstallCtas) {
            setTimeout(() => {
              product_plugin_location_config.has_been_setup = false;
            }, 500);
          }
        }, 50);
      });
      if (plugin_location && sellnow_config?.instant_locations?.find((loc) => loc === plugin_location)) {
        fire_snapoffer_autostart_event();
      }
    }

    async function setupLink() {
      const tplink = getCookie(`tp_link`) || getCookie(`tp_link_${dealer_id}`);
      if (tplink) {
        tp_linking_id = tplink;
      }

      const script = document.createElement("script");
      let url = `${plugin_ajax_url}/link/${encodeURIComponent(dealer_id)}/dealer.js`;
      if (tplink) {
        url += `?lid=${tplink}`;
      }
      script.setAttribute("src", url);
      script.setAttribute("async", "true");
      return new Promise<void>((resolve, reject) => {
        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          resolve();
        };
        document.body.appendChild(script);
      });
    }

    function addCssToHead(css_type: string, css_contents: string) {
      const id = `tradepending-css-${css_type}`;
      const css = `<style id='${id}'>${css_contents}</style>`;
      const styleElement = document.getElementById(id);
      if (styleElement) {
        styleElement.outerHTML = css;
      } else {
        document.head.insertAdjacentHTML("beforeend", css);
      }
    }

    function addCssLinkToHead(css_type: string, css_url: string) {
      const id = `tradepending-css-${css_type}`;

      const css = `<link id='${id}' href='${css_url}' rel="stylesheet" type='text/css' media='screen'>`;

      if (jQuery(id).length > 0) {
        jQuery(id).replaceWith(css);
      } else {
        jQuery("head").prepend(css);
      }
    }

    function apply_insert_method(element, method, html) {
      if (method === "before") {
        element.before(html);
      } else if (method === "after") {
        element.after(html);
      } else if (method === "prepend") {
        element.prepend(html);
      } else if (method === "html") {
        element.html(html);
      } else if (method === "replaceWith") {
        element.replaceWith(html);
      } else {
        element.append(html);
      }
    }

    async function waitForDDCAPI(plugin_location: string) {
      if (!plugin_location) {
        return;
      }
      const use_ddc_api = (plugin_config && plugin_config[plugin_location]?.use_ddc_api) || (superlative_config && superlative_config[plugin_location]?.use_ddc_api) || (autoapr_config && autoapr_config[plugin_location]?.use_ddc_api);

      let interval = 0;
      const sleepTime = 1000;

      while (use_ddc_api && !window.DDC?.API && interval <= 5) {
        await tpUtil.sleep(sleepTime * ++interval);
      }
      if (window.DDC?.API) {
        return new window.DDC.API("tradepending");
      }
    }

    async function insert_widget(plugin, widget_html, using_default_plugin_location = undefined) {
      debugMessage("TradePending: insert_widget");
      if (DDC_API && tpUtil.isDdcLandingPage() && !tpUtil.isOldDdcLandingPage()) {
        debugMessage("TradePending: Using DDC API for SNAP Landing Page insert");
        await installSnapDdcLandingPage(plugin, widget_html);
      } else if (DDC_API && plugin.use_ddc_api) {
        debugMessage("TradePending: Using DDC API for SNAP insert");
        await installSnapDdcWidget(plugin, widget_html);
      } else {
        insert_default(plugin, widget_html, using_default_plugin_location, 0);
      }
    }

    if (jQuery.isReady && !snap_widget_installed) {
      install();
    } else {
      jQuery(window).on("load", function () {
        if (!snap_widget_installed) {
          install();
        }
      });
    }
    window.tradependingSetup = function () {
      debugMessage("tradependingSetup calling install");
      snap_widget_installed = false;
      install();
    };
    window.tradendingLaunchSnapOverlay = function (vehicle) {
      launchContactForm(vehicle);
    };
    window.tradependingLaunchSnapOverlay = function (vehicle) {
      launchContactForm(vehicle);
    };
    return true;
  }

  return true;
})(window, document, window.jQuery, window.TradePendingPlugin);
